import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Input, Button} from "antd";

import styles from './index.module.scss'

function Adder(props) {
  const {min, max, step, size, value, defaultValue, onChange, style} = props
  const [num, setNum] = useState();


  useEffect(() => {
    setNum(value || defaultValue)
  }, ["num"])


  const judgeNum = (val) => {
    let num
    if (["", "-"].includes(val)) {
      num = val;
    } else {
      if (!isNaN(val)) {
        num = parseInt(val)
      } else {
        num = val.slice(0, -1)
      }
      if (num && num < min) {
        num = min
      }
      if (num > max) {
        num = max
      }
    }
    return num
  }

  const changeNum = e => {
    let val = judgeNum(e.target.value)
    setNum(val)
    onChange && onChange(val)
  }
  const action = type => {
    let val;
    switch (type) {
      case "plus":
        if (isNaN(num))
          val = min
        else
          val = +num >= max ? num : +num + step
        break;

      case "minus":
        val = +num <= min ? num : +num - step
        break;
    }
    setNum(val)
    onChange && onChange(val)
  }
  return (
    <Input addonBefore={<Button className={styles.adder_btn}
                                type="link"
                                size={size}
                                disabled={isNaN(num) || +num <= min}
                                onClick={() => action("minus")}>-</Button>}
           addonAfter={<Button className={styles.adder_btn}
                               type="link"
                               size={size}
                               disabled={+num >= max}
                               onClick={() => action("plus")}>+</Button>
           }
           style={Object.assign({textAlign: "center"}, style)}
           size={size}
           value={num}
           onChange={(e) => changeNum(e)}/>
  );
}


Adder.defaultProps = {
  defaultValue: 1,
  min: 1,
  max: 999999999,
  step: 1,
  size: "default",
}
Adder.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  style: PropTypes.object || PropTypes.bool,
  size: PropTypes.oneOf(["small", "default", "large"]),
  defaultValue: PropTypes.number,
  value: PropTypes.any,
  onChange: PropTypes.func
};

export default Adder;
