import React, { Component } from 'react';
import { Breadcrumb } from 'antd'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import styles from './index.module.scss'

class Bread extends Component {
	constructor(props) {
		super(props)
		this.state = {
		}
	}

	render () {
		const {breadArr}=this.props
		return (
			<div className={styles.bread_container}>
				<Breadcrumb style={{ margin: 10 }}>
					{breadArr.map(item => (
						<Breadcrumb.Item key={item.key} routes={item.route}>
							<Link to={item.route ? item.route : '#'}>	{item.name}</Link>
						</Breadcrumb.Item>
					))}
				</Breadcrumb>
			</div>

		)
	}
}

Bread.propTypes={
	breadArr:PropTypes.array.isRequired
}

export default Bread
