import {action, observable} from "mobx";
import httpClient from 'utils/httpClient'
import api from 'config/api'

const {setting} = api

class SettingStore {
  @observable purchaseRecord = [];
  @observable productList = [];
  @observable usingProduct={};

  /**
   * 获取产品列表
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getProductList = async params => {
    const data = await httpClient.get(setting.GET_PRODUCT_LIST, {params})
    if (data.success) {
      this.productList = data.data
    }
  }

  /**
   * 获取正在使用的产品信息
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getUsingProductDetail=async params=>{
    const data =await httpClient.get(setting.GET_USING_PRODUCT_DETAIL,{params});
    if(data.success){
      this.usingProduct=data.data
    }
  }

  /**
   * 获取购买记录列表
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getPurchaseRecords = async params => {
    const data = await httpClient.get(setting.GET_PURCHASE_RECORDS, {params})
    if (data.success) {
      this.purchaseRecord = data.data
    }
  }
}

export default new SettingStore()
