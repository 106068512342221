import {action, observable} from "mobx"

import httpClient from "utils/httpClient"
import api from "config/api"
import {addKey} from "utils/tools";

const {goods} = api;


class GoodsStore {
  @observable goodsList = { /* 商品列表 */
    list: [],
    total: 0
  }
  @observable customerLevelList = []/* 客户等级列表 */
  @observable goodsDetail = {}/* 商品详情 */

  /**
   * 获取企业配置（商品列表状态）
   * @param params
   * @returns {Promise<T>}
   */
  @action
  getGoodsListStatus = async params => {
    const data = await httpClient.post(goods.POST_GET_GOODS_LIST_STATUS, {...params})
    if (data.success)
      return data.data
  }

  /**
   * 设置企业配置（商品列表状态）
   * @param params
   * @returns {Promise<T>}
   */
  @action
  setGoodsListStatus = async params => {
    const data = await httpClient.post(goods.POST_SET_GOODS_LIST_STATUS, {...params})
    return data.success
  }


  /**
   * 获取商品列表
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getGoodsList = async params => {
    const data = await httpClient.post(goods.GET_GOODS_LIST, {...params});
    if (data.success) {
      this.goodsList = addKey(data.data)
    }
  }

  /**
   * 获取客户等级列表（设置价格）
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getCustomerLevelList = async params => {
    const data = await httpClient.post(goods.POST_GET_CUSTOMER_LEVEL, {...params})
    if (data.success) {
      this.customerLevelList = data.data
    }
  }

  /**
   * 商品批量导入
   * @param params
   * @returns {Promise<any | AxiosResponse<T>>}
   */
  @action
  postUploadGoods = async params => {
    const data = await httpClient.post(goods.POST_UPLOAD_GOODS, params,{withCredentials: true})
    if(data.success){
      return data.data
    }
  }

  /**
   * 商品录入新增
   * @param params
   * @returns {Promise<any | AxiosResponse<T>>}
   */
  @action
  postAddGoods = async params => {
    const data = await httpClient.post(goods.POST_ADD_GOODS, {...params})
    return data
  }

  /**
   * 商品编辑
   * @param params
   * @returns {Promise<any | AxiosResponse<T>>}
   */
  @action
  postEditGoods = async params => {
    const data = await httpClient.post(goods.POST_EDIT_GOODS, {...params})
    return data
  }

  /**
   * 获取商品详情
   * @param params
   * @returns {Promise<any | AxiosResponse<T>>}
   */
  @action
  getGoodsDetail = async params => {
    const data = await httpClient.post(goods.POST_GET_GOODS_DETAIL, {...params})
    if (data.success) {
      this.goodsDetail = data.data
    }
  }

  /**
   * 商品分类列表
   * @param params
   * @returns {Promise<any | AxiosResponse<T>>}
   */
  @action
  getGoodsCategoryTree = async params => {
    const data = await httpClient.post(goods.POST_GET_GOODS_CATEGORY_TREE, {...params})
    return data
  }

  /**
   * 商品分类列表(加权限)
   * @param params
   * @returns {Promise<any | AxiosResponse<T>>}
   */
  @action
  getGoodsCategoryManageTree = async params => {
    const data = await httpClient.post(goods.POST_GET_GOODS_CATEGORY_MANAGE_TREE, {...params})
    return data
  }

  /**
   * 添加商品分类
   * @param params
   * @returns {Promise<any | AxiosResponse<T>>}
   */
  @action
  addGoodsCategory = async params => {
    const data = await httpClient.post(goods.POST_ADD_GOODS_CATEGORY, {...params})
    return data
  }

  /**
   * 编辑商品分类
   * @param params
   * @returns {Promise<any | AxiosResponse<T>>}
   */
  @action
  updateGoodsCategory = async params => {
    const data = await httpClient.post(goods.POST_UPDATE_GOODS_CATEGORY, {...params})
    return data
  }

  /**
   * 删除商品分类
   * @param params
   * @returns {Promise<any | AxiosResponse<T>>}
   */
  @action
  delGoodsCategory = async params => {
    const data = await httpClient.post(goods.POST_DELETE_GOODS_CATEGORY, {...params})
    return data
  }

  /**
   * 获取商品分类关联的商品数
   * @param params
   * @returns {Promise<any | AxiosResponse<T>>}
   */
  @action
  getCategoryGoodsCount = async params => {
    const data = await httpClient.post(goods.POST_GET_CATEGORY_GOODS_COUNT, {...params})
    return data
  }

  /**
   * 商品品牌列表(加权限)
   * @param params
   * @returns {Promise<any | AxiosResponse<T>>}
   */
  @action
  getGoodsBrandManageList = async params => {
    const data = await httpClient.post(goods.POST_GET_GOODS_BRAND_MANAGE_LIST, {...params})
    return data
  }

  /**
   * 商品品牌列表
   * @param params
   * @returns {Promise<any | AxiosResponse<T>>}
   */
  @action
  getGoodsBrandList = async params => {
    const data = await httpClient.post(goods.POST_GET_GOODS_BRAND_LIST, {...params})
    return data
  }

  /**
   * 添加商品品牌
   * @param params
   * @returns {Promise<any | AxiosResponse<T>>}
   */
  @action
  addGoodsBrand = async params => {
    const data = await httpClient.post(goods.POST_ADD_GOODS_BRAND, {...params})
    return data
  }

  /**
   * 编辑商品品牌
   * @param params
   * @returns {Promise<any | AxiosResponse<T>>}
   */
  @action
  updateGoodsBrand = async params => {
    const data = await httpClient.post(goods.POST_UPDATE_GOODS_BRAND, {...params})
    return data
  }

  /**
   * 删除商品品牌
   * @param params
   * @returns {Promise<any | AxiosResponse<T>>}
   */
  @action
  delGoodsBrand = async params => {
    const data = await httpClient.post(goods.POST_DELETE_GOODS_BRAND, {...params})
    return data
  }

  /**
   * 获取商品品牌关联的商品数
   * @param params
   * @returns {Promise<any | AxiosResponse<T>>}
   */
  @action
  getBrandGoodsCount = async params => {
    const data = await httpClient.post(goods.POST_GET_BRAND_GOODS_COUNT, {...params})
    return data
  }

  /**
   * 上架商品
   * @param params
   * @returns {Promise<*>}
   */
  @action
  upShelfGoods=async params=>{
    const data=await httpClient.post(goods.POST_UP_SHELF_GOODS,{...params})
    return data.success;
  }
  /**
   * 下架商品
   * @param params
   * @returns {Promise<*>}
   */
  @action
  downShelfGoods=async params=>{
    const data=await httpClient.post(goods.POST_DOWN_SHELF_GOODS,{...params})
    return data.success;
  }

  /**
   * 删除商品
   * @param params
   * @returns {Promise<*>}
   */
  @action
  delGoods=async params=>{
    const data=await httpClient.post(goods.POST_DEL_GOODS,{...params})
    return data.success;
  }

  /**
   * 获取商品卖点列表
   * @param params
   * @returns {Promise<*>}
   */
  @action
  getSellingPointList=async params=>{
    const data=await httpClient.post(goods.POST_GET_SELLING_POINT_LIST,{...params})
    if(data.success){
      return data.data.list.map(item=>({
        name:item.text,
        isUsed:false,
      }))
    }
  }
}

export default new GoodsStore()
