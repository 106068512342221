import {observable, action} from "mobx";
import {message} from "antd";
import httpClient from "utils/httpClient";
import api from "config/api"

const {common, customer, applet} = api

class CommonStore {
  /* 用户信息 */
  @observable globalLoading = false;
  @observable userInfo = {};
  @observable companyType = []
  @observable brandList = []
  @observable userRights = {permissionKeys: [], isLoaded: false};

  /**
   * 获取短信验证码
   * @param params
   * @returns {Promise<*>}
   */
  @action
  getSmsCode = async params => {
    const data = await httpClient.post(common.POST_SMS_CODE, {...params});
    return data
  }

  /**
   * 登录
   * @param params
   * @returns {Promise<void>}
   */
  @action
  postLogin = async params => {
    const data = await httpClient.post(common.POST_LOGIN, {...params});
    if (data.success) {
      localStorage.setItem("token", data.data.authorityToken)
      localStorage.setItem("userInfo", JSON.stringify({
        companyId: data.data.companyId,
        userName: data.data.nickName,
        outUserId: data.data.outUserId,
        userId: data.data.userId,
        sellerId: data.data.sellerId
      }))
      window.location.href = "/workbench"
    } else {

    }
  }

  /**
   * 登录
   * @param params
   * @returns {Promise<void>}
   */
  @action
  postLoginNew = async params => {
    const data = await httpClient.post(common.POST_LOGIN_NEW, {...params});
    if (data.success) {
      localStorage.setItem("token", data.data.authorityToken)
      localStorage.setItem("userInfo", JSON.stringify({
        companyId: data.data.companyId,
        userName: data.data.nickName,
        outUserId: data.data.outUserId,
        userId: data.data.userId,
        sellerId: data.data.sellerId
      }))
      window.location.href = "/workbench";
    } else {

    }
  }

  /**
   * 注册
   * @param params
   * @returns {Promise<void>}
   */
  @action
  postRegister = async params => {
    const data = await httpClient.post(common.POST_REGISTER_NEW, {...params});
    if (data.success) {
      localStorage.setItem("token", data.data.authorityToken)
      localStorage.setItem("userInfo", JSON.stringify({
        companyId: data.data.companyId,
        userName: data.data.nickName,
        outUserId: data.data.outUserId,
        userId: data.data.userId,
        sellerId: data.data.sellerId
      }))
     return data;
    } else {
      return false;
    }
  }

  /**
   * 忘记密码第一步
   * @param params
   * @returns {Promise<void>}
   */
  @action
  checkResetAccount = async params => {
    const data = await httpClient.post(common.POST_CHECK_RESET_ACCOUNT, {...params});
    return data;
  }

  /**
   * 忘记密码第二步
   * @param params
   * @returns {Promise<void>}
   */
  @action
  resetPassword = async params => {
    const data = await httpClient.post(common.POST_RESET_PASSWORD, {...params});
    return data;
  }

  /**
   * 获取授权二维码
   * @param params
   * @returns {Promise<*>}
   */
  @action
  getAuthQrcode = async params => {
    const data = await httpClient.get(common.GET_AUTH_QRCODE, {params})
    return data
  }

  /**
   * 退出登录
   * @param params
   * @returns {Promise<void>}
   */
  @action
  postLogout = async params => {
    const data = await httpClient.post(common.POST_LOGOUT, {...params});
    if (data.success) {
      localStorage.removeItem("token");
      localStorage.removeItem("userInfo");

      window.location.href = "/home"
    } else {

    }
  }

  /**
   * 上传单个文件
   * @param params
   * @returns {Promise<{success}|any|AxiosResponse<T>>}
   */
  @action
  uploadFile=async params=>{
    const data=await httpClient.post(common.POST_UPLOAD_FILE,params,{withCredentials: true})
    if(data.success){
      return data
    }
  }

  /**
   * 获取企业类型列表
   * @returns {Promise<void>}
   */
  @action
  getCompanyType = async () => {
    const data = await httpClient.get(common.GET_SYS_DICT, {
      params: {
        dictType: "bestgiftIndustryType"
      }
    });
    if (data.success) {
      this.companyType = data.data.map((item) => ({
        value: item.dictKey,
        name: item.dictValue
      }));
    }

  }

  /**
   * 获取商品类型
   * @param params
   * @returns {Promise<*>}
   */
  @action
  getGoodsCategory = async params => {
    const data = await httpClient.post(common.GET_GOODS_CATEGORY, {...params})
    if (data.success) {
      return data.data
    }
  }

  /**
   * 获取商品品牌
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getGoodsBrand = async params => {
    const data = await httpClient.post(common.GET_GOODS_BRAND, {...params})
    if (data.success) {
      this.brandList = data.data.list
    }
  }

  /**
   * 通过id获取品牌
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getGoodsBrandById = async params => {
    const data = await httpClient.post(common.GET_GOODS_BRAND_BY_ID, {...params})
    if (data.success) {
      this.brandList = [data.data]
    }
  }

  /**
   * 获取当前用户权限列表
   * @param {*} params
   */
  @action
  async getUserRights(params) {
    const res = await httpClient.post(common.POST_GET_USER_RIGHTS, {...params});
    if (res.success) {
      if (['/', '/home'].includes(window.location.pathname)) {
        window.location.href = '/workbench';
      }
      this.globalLoading = false;
      this.userRights = {
        permissionKeys: res.data.map(item => item.permission),
        isLoaded: true,
      };
    }
    return res;
  }

  /**
   * 获取工作台统计数据
   * @param params
   * @returns {Promise<*>}
   */
  @action
  getMemberStatistics = async params => {
    const data = await httpClient.post(common.GET_MEMBER_STATISTICS, {params})
    return data
  }

  /**
   * 检验用户名
   * @param params
   * @returns {Promise<*>}
   */
  @action
  checkUserName = async params => {
    const data = await httpClient.get(common.GET_CHECK_USER_NAME, {params})
    return data
  }

  /**
   * 获取小程序状态
   * @param params
   * @returns {Promise<*>}
   */
  @action
  getMiniprogramStatus = async params => {
    const data = await httpClient.get(common.GET_MINIPROGRAM_STATUS, {params})
    if (data.success) {
      return data.data
    }
  }

  @action
  getFirstLoginStatus = async params => {
    const data = await httpClient.get(common.GET_FIRST_LOGIN_STATUS, {params})
    if(data.success){
      return data.data
    }
  }
}

export default new CommonStore();
