import React, {useState, useEffect} from 'react';
import {Button} from "antd";
import {RightOutlined} from '@ant-design/icons'

import stores from 'stores'
import styles from './index.module.scss'

const {commonStore} = stores

function Floating(props) {

  const [active, setActive] = useState(false);
  const [data, setData] = useState({
    // authStatus: 1,
    // type: 1,
    // qrCodeUrl: "https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/202006041050590001817486.jpg"
  })

  useEffect(() => {
    getStatus();
  }, [])

  const getStatus = async () => {
    let res = await commonStore.getMiniprogramStatus({})
    setData(res)
  }
  const renderContent = () => {
    let dom = null;
    let flag = +data.authStatus === 1 && +data.type === 1
    dom = flag ?
      <div className={styles.content_container}>
        <p className={styles.content_img_tip}>专属品牌小程序</p>
        <div className={styles.img_container}>
          <img src={data.qrCodeUrl} alt=""/>
        </div>
        <p className={styles.content_img_tip}>扫码进入我的工作台</p>
      </div> :
      <div className={styles.content_container}>
        <p className={styles.content_tip}>您没有开通您的小程序工作台</p>
        <Button className={styles.open_btn} type="primary"
                onClick={() => window.open("/app-workbench/setting/app-authorize", "_self")}>立即配置</Button>
      </div>


    return dom;
  }

  return (
    <div className={`${styles.floating_container} ${active ? styles.active : ""} `}>
      <div className={styles.floating_header}>
        <div className={styles.arrow_wrapper} onClick={() => setActive(!active)}>
          <div className={styles.arrow_container}><RightOutlined/></div>
        </div>
      </div>
      {active ? renderContent() : <p className={styles.floating_title}>小程序端工作台</p>}
    </div>
  );
}


export default Floating;
