import React, {Component} from "react";
import {Button, Divider, Menu, message} from "antd";
import {inject, observer} from "mobx-react";
import PropTypes from "prop-types"
import qs from 'qs'

// import GuideCover from "../GuideCover"
import styles from "./index.module.scss";
import {routers} from "config/route";
import {config} from 'config'

const {theme} = config

const {Item} = Menu
const pageTitleMap = {
  login: '会员登录',
  register: '会员注册',
  resetPassword: '找回密码',
}

@inject(...["commonStore", "localStore"])
@observer
class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      showFeeModal: false,
      menuCurrent: "home"
    }
  }

  async componentDidMount() {
    //获取用户名
    let userInfo = JSON.parse(localStorage.getItem("userInfo"))
    this.setState({
      userName: userInfo ? userInfo.userName : ""
    })
  }

  /**
   * 点击menu
   * @param val
   */
  changeMenu = (val) => {
    const {changeTopBarMenu} = this.props.localStore;
    let item = routers.find(item => +item.id === +val.key)
    changeTopBarMenu(item);

    window.location.href = item.route;

  }
  logout = () => {
    this.props.commonStore.logout()
  };

  render() {
    const {userName} = this.state;
    const {type, page, menuKeys = []} = this.props;
    const {topBarMenu = {},setGuideShow} = this.props.localStore;
    const topBarSelectkey = [1, 4, 5, 6].includes(topBarMenu.id) ? '1' : topBarMenu.id + '';
    let top = null;

    switch (type) {
      case "main":
        top = <div className={`${styles.top_bar} ${styles.main_bar}`}>
          <div className={styles.menu_container}>
            <Menu mode="horizontal" onClick={this.changeMenu} selectedKeys={[topBarSelectkey]}>
              {routers.filter((item, index) => index < 3 && menuKeys.includes(item.key)).map((item, index) => {
                  const isSelect = item.id === +topBarSelectkey;
                  return (
                    !item.disabled && <Menu.Item key={item.id}
                                                 className={`${styles.menu_item} ${isSelect ? styles.menu_item_selected : ''}`}>
                      <div className={styles.menu_item_content}>
                        <i className={`${styles.icon_blt} ${styles[item.iconClass]}`}/>
                        <div className={styles.menu_name}>{item.name}</div>
                      </div>
                    </Menu.Item>
                  )
                }
              )}
            </Menu>
          </div>
          <div className={styles.right}>
            <div className={styles.message_center}></div>
            <div className={styles.info_container}>
              <span>{userName}</span>
              <Divider type="vertical"/>
              <Button
                type="link"
                style={{padding: 0}}
                onClick={() => {
                  localStorage.removeItem('token');
                  window.location.href = '/login';
                }}
              >
                退出
              </Button>
            </div>
          </div>
        </div>
        break;
      case "login":
        top = <div className={`${styles.top_bar} ${styles.login_bar}`}>
          <div className={styles.bar_content}>
            <div className={styles.left}>
              <span>小程序商城工作台</span>
              <Divider type="vertical" style={{height: theme.sizes["text-20"]}}/>
              <span>{pageTitleMap[page]}</span>
            </div>
            <div className={styles.right}>
              <span>全国统一客服热线:400-160-1160</span>
            </div>
          </div>
        </div>
        break;
      case "workbench":
        top = <div className={`${styles.top_bar} ${styles.workbench_bar}`}>
          <div className={styles.bar_content}>
            <div className={styles.menu_container}>
              <Menu mode="horizontal" onClick={this.changeMenu} selectedKeys={[topBarSelectkey]}>
                {routers.filter((item, index) => index < 3 && menuKeys.includes(item.key)).map((item, index) => {
                    const isSelect = item.id === +topBarSelectkey;
                    return (
                      !item.disabled && <Menu.Item key={item.id}
                                                   className={`${styles.menu_item} ${isSelect ? styles.menu_item_selected : ''}`}>
                        <div className={styles.menu_item_content}>
                          <i className={`${styles.icon_blt} ${styles[item.iconClass]}`}/>
                          <div className={styles.menu_name}>{item.name}</div>
                        </div>
                      </Menu.Item>
                    )
                  }
                )}
              </Menu>
            </div>
            <div className={styles.right}>
              <div className={styles.message_center}></div>
              <Button type={"link"} onClick={()=>setGuideShow(true)}>新手引导</Button>
              <div className={styles.info_container}>
                <span>{userName}</span>
                <Divider type="vertical"/>
                <Button
                  type="link"
                  style={{padding: 0}}
                  onClick={() => {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                  }}
                >
                  退出
                </Button>
              </div>
            </div>
          </div>
          {/*<GuideCover/>*/}
        </div>
        break;
      case "home":
        top = <div className={`${styles.top_bar} ${styles.full_bar}`}>
          <div className={styles.bar_content}>home bar</div>
        </div>
        break;
    }

    return top;
  }
}

TopBar.propTypes = {
  type: PropTypes.oneOf(["main", "login", "workbench", "home"]).isRequired,
  page: PropTypes.oneOf(["login", "register", "resetPassword"]),
  menuKeys: PropTypes.array,
}

export default TopBar;
