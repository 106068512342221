// 卡券查找
// 绑定类型
const bindTypeEunm = new Map([
  [1, '卡密'],
  [2, '手机号'],
  [3, '工号'],
  [4, '邮箱'],
  [5, '身份证'],
])

const invoiceMap = [0, 6, 9, 13]

const menuInfoData = {
  topbar: {
    controlMenuKeys: ['structure'],
  },
  workbench: {
    controlMenuKeys: ['resource', 'app-workbench'],
  },
  leftnav: {
    controlMenuKeys: [
      "goods", "goodsManage", "goodsManageOps", "goodsCategory", "goodsCategoryOps", "goodsBrand", "goodsBrandOps",
      "scheme", "schemePublicManage", "employeeProgramme",
      "material", "materialManage",
      "app-workbench", "market-operate", "micropage", "micropageOps", "recommend", "recommendOps", "marketing", "marketingOps","article", "articleOps","message",  "messageOps", "app-authorize",
      "companyCustomer", "customer", "customerOps", "contacts", "contactsOps", "potential", "potentialOps", "audit", "auditOps", "level", "levelOps", "label", "labelOps",
      'structure', 'staff-manage', "staff-manage-ops", 'role-permission', "role-permission-ops",
      "company-info", "product-buy",
    ],
  },
  // 默认全部key
  defaultMenuKeys: [
    "workbench",
    "resource",
    "goods", "goodsManage", "goodsManageOps", "goodsCategory", "goodsCategoryOps", "goodsBrand", "goodsBrandOps",
    "scheme", "schemePublicManage", "employeeProgramme",
    "material", "materialManage",
    "app-workbench", "app-operate", "market-operate", "micropage", "micropageOps", "recommend", "recommendOps", "marketing", "marketingOps","article", "articleOps","message",  "messageOps",  "app-authorize",
    "companyCustomer", "customer", "customerOps", "contacts", "contactsOps", "potential", "potentialOps", "audit", "auditOps", "level", "levelOps", "label", "labelOps", "myCustomer",
    "structure", "staff-manage", "staff-manage-ops", "role-permission", "role-permission-ops",
    "setting", "personal-info", "company-info", "product-buy",
  ],
  // routeKey映射permissionKey
  menuKeyMap: {
    resource: 'bestgift:smallapp:sourceStore', // 资源库
    goods: 'bestgift:smallapp:itemStore', // 商品库
    goodsManage: 'bestgift:smallapp:item:view', // 商品管理-查看
    goodsManageOps: 'bestgift:smallapp:item:operate', // 商品管理-编辑
    goodsCategory: 'bestgift:smallapp:itemCategory:view', // 商品分类-查看
    goodsCategoryOps: 'bestgift:smallapp:itemCategory:operate', // 商品分类-编辑
    goodsBrand: 'bestgift:smallapp:itemBrand:view', // 商品品牌-查看
    goodsBrandOps: 'bestgift:smallapp:itemBrand:operate', // 商品品牌-编辑
    scheme: 'bestgift:smallapp:schemeStore', // 方案库
    schemePublicManage: 'bestgift:smallapp:commonScheme', // 公共方案
    employeeProgramme: 'bestgift:smallapp:allScheme', // 所有员工方案
    // material: 'bestgift:smallapp:materialStore', // 素材库
    // materialManage: 'bestgift:smallapp:material', // 素材库管理

    'app-workbench': 'bestgift:smallapp:workbench', // 小程序工作台
    'market-operate': 'bestgift:smallapp:mall', // 商城运营
    micropage: 'bestgift:smallapp:page:view', // 微页面-查看
    micropageOps: 'bestgift:smallapp:page:operate', // 微页面-编辑
    recommend: 'bestgift:smallapp:itemRecommend:view', // 爆品推荐-查看
    recommendOps: 'bestgift:smallapp:itemRecommend:operate', // 爆品推荐-编辑
    marketing: 'bestgift:smallapp:activity:view', // 营销活动
    marketingOps: "bestgift:smallapp:activity:operate",
    article: 'bestgift:smallapp:Information:view', // 文章资讯
    articleOps: "bestgift:smallapp:Information:operate",
    message: 'bestgift:smallapp:message:view', // 消息推送
    messageOps: "bestgift:smallapp:message:operate",
    'app-authorize': 'bestgift:smallapp:assign', // 小程序授权

    companyCustomer: 'bestgift:smallapp:customer:sub', // 公司客户
    customer: 'bestgift:smallapp:customer:manage:view', // 客户-查看
    customerOps: 'bestgift:smallapp:customer:manage:operate', // 客户-编辑
    contacts: 'bestgift:smallapp:customer:contacts:view', // 联系人-查看
    contactsOps: 'bestgift:smallapp:customer:contacts:operate', // 联系人-编辑
    potential: 'bestgift:smallapp:customer:potential:view', // 潜在客户-查看
    potentialOps: 'bestgift:smallapp:customer:potential:operate', // 潜在客户-编辑
    audit: 'bestgift:smallapp:customer:levelApproval:view', // 等级调整审批
    auditOps: 'bestgift:smallapp:customer:levelApproval:operate', // 等级调整审批
    level: 'bestgift:smallapp:customer:level:view', // 客户等级
    levelOps: 'bestgift:smallapp:customer:level:operate', // 客户等级
    label: 'bestgift:smallapp:customer:label:view', // 客户标签
    labelOps: 'bestgift:smallapp:customer:label:operate', // 客户标签
    myCustomer: 'bestgift:smallapp:customer:my', // 我的客户

    structure: 'bestgift:organizational:structure', // 组织架构
    'staff-manage': 'bestgift:organizational:structure:employee:view', // 员工管理-查看
    'staff-manage-ops': 'bestgift:organizational:structure:employee:operate', // 员工管理-编辑
    'role-permission': 'bestgift:organizational:structure:roleAssign:view', // 角色权限-查看
    'role-permission-ops': 'bestgift:organizational:structure:roleAssign:operate', // 角色权限-编辑

    setting: 'bestgift:setting', // 设置
    'personal-info': 'bestgift:setting:person', // 我的信息
    'company-info': 'bestgift:setting:company', // 公司信息
    'product-buy': 'bestgift:setting:paymentMember', // 产品购买
  }
}

export {
  bindTypeEunm,
  invoiceMap,
  menuInfoData,
}
