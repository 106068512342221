import {action, observable} from "mobx";

import httpClient from "utils/httpClient";
import api from 'config/api';
import {addKey} from "utils/tools";

const {scheme} = api

class SchemeStore {
  @observable publicSchemeList = {list: [], total: 0}

  /**
   * 获取公共方案列表
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getPublicSchemeList = async (params) => {
    const data = await httpClient.post(scheme.POST_GET_PUBLIC_SCHEME_LIST, {...params});
    if (data.success) {
      this.publicSchemeList = addKey(data.data)
      return addKey(data.data)
    }
  }

  /**
   * 添加公共方案
   * @param params
   * @returns {Promise<any | AxiosResponse<T>>}
   */
  @action
  addPublicScheme=async params=>{
    const data=await httpClient.post(scheme.POST_ADD_PUBLIC_SCHEME,{...params});
    return data;
  }

  /**
   * 公共方案操作
   * @param params
   * @returns {Promise<any | AxiosResponse<T>>}
   */
  @action
  publicSchemeAction=async params=>{
    const data=await httpClient.post(scheme.POST_PUBLIC_SCHEME_ACTION,{...params})
    return data;
  }
}

export default new SchemeStore();
