import React from 'react';
import PropTypes from 'prop-types';

import {config } from "config"
import styles from "../index.module.scss"

const {moduleImg}=config;

function PhoneHeader(props) {
const {name,onClick}=props
  return (
    <div className={styles.module_container} onClick={onClick}>
      <img src={moduleImg.phone_head} alt="" style={{width:"100%"}}/>
      <span className={styles.app_title}>{name}</span>
    </div>
  );

}

PhoneHeader.propTypes = {
  name:PropTypes.string,
  onClick:PropTypes.func
};

export default PhoneHeader;
