import { action, observable } from 'mobx';
import httpClient from '../../utils/httpClient';
import api from '../../config/api';

const { employee, role } = api;


class EmployeeStore {
  @observable employeeList = [];
  @observable employeePagination = {
    pageNo: 1,
    pageSize: 20,
  };
  
  /**
   * 获取公司账号
   * @param {*} params
   */
  @action
  async getCompanyAccount(params) {
    const res = await httpClient.get(employee.GET_COMPANY_ACCOUNT, { ...params });
    return res;
  }

   /**
   * 获取部门树
   * @param {*} params
   */
  @action
  async getDepartmentTree(params) {
    const res = await httpClient.post(employee.GET_DEPARTMENT_LIST, { ...params });
    return res;
  }

  /**
   * 获取公司/搜索 员工列表
   * @param {*} params
   */
  @action
  async getCompanyEmployeeList(params) {
    const res = await httpClient.post(employee.GET_EMPLOYEE_LIST_BY_COMPANY, { ...params });
    const { success, data } = res;
    if (success) {
      this.employeeList = data.list.map(item => ({ key: item.id, ...item }));
      this.employeePagination = {
        current: data.page,
        pageSize: data.pageSize,
        total: data.total,
      }
    }
    return res;
  }

  /**
   * 获取部门员工列表
   * @param {*} params
   */
  @action
  async getDepartmentEmployeeList(params) {
    const res = await httpClient.post(employee.GET_EMPLOYEE_LIST_BY_DEPARTMENT, { ...params });
    const { success, data } = res;
    if (success) {
      this.employeeList = data.list.map(item => ({ key: item.id, ...item }));
      this.employeePagination = {
        current: data.page,
        pageSize: data.pageSize,
        total: data.total,
      }
    }
    return res;
  }

  /**
   * 添加、编辑部门
   * @param {*} params
   */
  @action
  async departmentOps(params, type) {
    const res = await httpClient.post(employee[`POST_${type}_DEPARTMENT`], { ...params });
    return res;
  }

  /**
   * 删除部门
   * @param {*} params
   */
  @action
  async delDepartment(params) {
    const res = await httpClient.delete(employee.DELETE_DEPARTMENT, { params });
    return res;
  }

  /**
   * 添加、编辑员工
   * @param {*} params
   */
  @action
  async empolyeeOps(params, type) {
    const methodMap = {
      ADD: 'post',
      UPDATE: 'put',
    }
    const res = await httpClient[methodMap[type]](employee[`${methodMap[type].toUpperCase()}_${type}_EMPLOYEE`], { ...params });
    return res;
  }

  /**
   * 删除员工
   * @param {*} params
   */
  @action
  async delEmployee(params) {
    const res = await httpClient.delete(employee.DELETE_EMPLOYEE, { params });
    return res;
  }

  /**
   * 获取员工归属客户
   * @param {*} params
   */
  @action
  async getCustomerByEmployee(params) {
    const res = await httpClient.get(`${employee.GET_CUSTOMER_BY_EMPLOYEE}/${params.employeeId}`);
    return res;
  }

  /**
   * 获取公司所有员工id
   * @param {*} params
   */
  @action
  async getEmployeeByCompany(params) {
    const res = await httpClient.get(`${employee.GET_EMPLOYEE_BY_COMPANY}/${params.companyId}`);
    return res;
  }

  /**
   * 获取角色列表
   * @param {*} params
   */
  @action
  async getRoleList(params) {
    const res = await httpClient.post(role.GET_ROLE_LIST, { ...params });
    return res;
  }

  /**
   * 员工授权角色
   * @param {*} params
   */
  @action
  async employeeAuthRole(params) {
    const res = await httpClient.post(employee.POST_EMPLOYEE_AUTH_ROLE, { ...params });
    return res;
  }
}

export default new EmployeeStore();
