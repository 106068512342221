import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, Table, message, Popover, Button} from "antd";
import {inject, observer} from "mobx-react";

import {formatTime, addKey} from "utils/tools";
import styles from "../marketOperate.module.scss";

@inject("goodsStore")
@observer
class AddCategory extends Component {
  state = {
    pageNo: 1,
    pageSize: 20,
    data: {list: [], total: 0}
  }

  componentDidMount() {
    this._getData();
  }

  _getData = async () => {
    const {getGoodsCategoryTree} = this.props.goodsStore;
    const {pageNo, pageSize} = this.state
    let params = {pageNo, pageSize}
    let res = await getGoodsCategoryTree(params)
    if (res.success) {
      let arr = res.data.list.filter(item=>item.childNodes);
      arr=arr.map((item, index) => {
        item.key = index;
        item.nameTree = item.categoryName;
        item.goodsNum = null;
        item.childNodes = item.childNodes.map((itemIn, indexIn) => {
          itemIn.key = indexIn;
          itemIn.nameTree = `${item.categoryName}_${itemIn.categoryName}`;
          itemIn.goodsNum = null;
          delete itemIn.childNodes
          return itemIn
        })
        return item;
      })
      res.data.list = arr
      this.setState({
        data: res.data
      })
    }
  }
  getCategoryNum = async (categoryTree) => {
    const {getCategoryGoodsCount} = this.props.goodsStore;
    let res = await getCategoryGoodsCount({categoryTree})
    if (res.success) {
      let data = this.state.data
      let arr = this.state.data.list;
      let ids = categoryTree.split('_');
      let index = arr.findIndex(item => item.categoryTree === ids[0]);
      let indexIn = null;
      if (ids[1]) {
        indexIn = arr[index].childNodes.findIndex(itemIn => itemIn.categoryTree === categoryTree)
        arr[index].childNodes[indexIn].goodsNum = res.data + ""
      } else {
        arr[index].goodsNum = res.data + ""
      }
      data.list = arr;
      this.setState({
        data
      })
    }
  }

  renderTable = () => {
    const {item, onSelect} = this.props;
    const {data, pageNo} = this.state
    const columns = [{
      title: '分类名称',
      dataIndex: 'categoryName',
      render: (text) => <Popover content={text} title={false}>
        <span className={styles.table_name}>{text}</span>
      </Popover>
    }, {
      title: '商品数',
      dataIndex: 'goodsNum',
      align: "center",
      render: (text, record) => text || <Button
        type="link"
        size="small"
        onClick={() => this.getCategoryNum(record.categoryTree)}
      >显示</Button>
    }, {
      title: "操作",
      align: "center",
      fixed: "right",
      render: (text, record) => record.categoryId === item.categoryId ?
        <Button disabled style={{width: 80}}>已选择</Button> :
        <Button style={{width: 80}} onClick={() => onSelect(record)}>选择</Button>
    }]
    const pagination = {
      total: data.total,
      current: pageNo,
      showSizeChanger: true,
      showQuickJumper: true,
      pageSizeOptions: ["20", "50", "100"],
      defaultPageSize: 20,
      onChange: this.changePage,
      onShowSizeChange: this.changePageSize,
      showTotal: total => `共 ${total} 条数据`,
    }
    console.log("data.list=======",data.list)
    return <Table columns={columns}
                  dataSource={data.list}
                  pagination={pagination}
                  expandable={{
                    childrenColumnName: 'childNodes',
                  }}
                  rowKey={record => record.key}/>
  }

  /**
   * 页码改变
   * @param page
   * @param pageSize
   */
  changePage = (page, pageSize) => {
    this.setState({
      pageNo: page,
    }, this._getData)
  }

  /**
   * pageSize改变
   * @param current
   * @param size
   */
  changePageSize = (current, size) => {
    this.setState({
      pageNo: 1,
      pageSize: size,
    }, this._getData)
  }

  render() {
    const {visible, onCancel} = this.props;

    return (
      <Modal visible={visible}
             centered
             title="选择分类"
             onCancel={onCancel}
             footer={false}
             width={700}
             zIndex={2000}
             bodyStyle={{
               maxHeight: 600,
               overflowY: "auto",
             }}>
        {this.renderTable()}
      </Modal>
    );
  }
}

AddCategory.propTypes = {
  visible: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSelect: PropTypes.func
};

export default AddCategory;
