import axios from "axios";
import {notification} from "antd";
import {mainHost, DATA_CODE} from "../config";
import qs from "qs";

const service = axios.create({
  baseURL: mainHost,
  timeout: 10000
});

service.interceptors.request.use(
  config => {
    config.headers["Content-Type"] = "application/json;charset=UTF-8"

    // config.headers["authority_token"] = "0ShwVbADY9VHrUC61BiS6HssKZ4hFKGc-202004091445240008755651-2"
    config.headers["authority_token"] = localStorage.getItem("token");

    // if (config.method === "post") {
    //     config.data = qs.stringify(config.data);
    // }
    return config;
  },
  err => {
    notification.error({
      title: "发送请求失败",
      message: err
    });
    return false;
  }
);
service.interceptors.response.use(
  res => {
    const {status, data, statusText} = res;
    return new Promise((resolve, reject) => {
      if (res.config.url === '/gift/wechat/miniapp/betaMatrix') {
        return resolve(data);
      }
      if (status < 200 || status > 300) {
        notification.error({
          message: `请求错误: ${status}`,
          description: statusText
        });
      } else if (!data.success) {
        notification.error({
          message: "提示",
          description: data.message
        });
        if (data.code === DATA_CODE.LOGIN_ERROR) {
          localStorage.removeItem('token');
          window.location.href = "/login";
        }
        return reject(data)
      } else {
        return resolve(data || true);
      }
      return reject()
    })
  },
  err => {
    // const { status, statusText } = err.response;

    notification.error({
      title: "提示",
      message: "系统错误"
    });
    return false;
  }
);

export default service;
