import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Modal, Descriptions, Divider, Button, message} from "antd";
import Qrcode from 'qrcode-react'

import ActivityDetail from '../../ActivityDetail'
import styles from './index.module.scss'


function MarketOperateActivityDetail(props) {
  const {visible, item, onCancel} = props


  const downloadQrcode = () => {
    let canvas = Array.from(document.getElementsByTagName("canvas")).filter(item => item.clientHeight === 200 && item.clientWidth === 200)[0]
    console.log(document.getElementsByTagName("canvas"))
    const url = canvas.toDataURL();
    const a = document.createElement('a');
    const event = new MouseEvent('click');
    a.download = '活动二维码';
    a.href = url;
    a.dispatchEvent(event);

  }
  return (
    <Modal visible={visible} onCancel={onCancel} title="活动详情页" footer={false} width={700}>
      <div className={styles.content_container}>
        <div className={styles.content_left}>
          <ActivityDetail item={item}/>
        </div>
        <Divider type="vertical"/>
        <div className={styles.content_right}>
          <Descriptions layout="vertical" column={1}>
            <Descriptions.Item label="活动链接">
              <div className={styles.desp_content}>
                <p className={styles.activity_url}>{item.activityUrl}</p>
              </div>
            </Descriptions.Item>
            <Descriptions.Item label="活动二维码">
              <div className={styles.desp_content}>
                <div className={styles.qrcode_container}>
                  <Qrcode value={item.activityUrl} size={200}/>
                  <Button type="primary" style={{marginTop: 16}} onClick={downloadQrcode}>点击下载</Button>
                </div>
              </div>
            </Descriptions.Item>
            {/*<Descriptions.Item label="活动分享海报">*/}
            {/*  <div className={styles.desp_content}></div>*/}
            {/*</Descriptions.Item>*/}
          </Descriptions>
        </div>
      </div>
    </Modal>
  );

}

MarketOperateActivityDetail.propTypes = {
  visible: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
}
export default MarketOperateActivityDetail;
