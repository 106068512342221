import React, {Component} from "react";
import {Menu, Button} from "antd";
import {DoubleRightOutlined} from "@ant-design/icons"

import styles from "./index.module.scss";
import {routers} from "config/route";
import {withRouter} from "react-router-dom";
import {inject, observer} from "mobx-react";

const {SubMenu} = Menu;

@inject(...["commonStore", "localStore"])
@withRouter
@observer
class LeftNav extends Component {
  state = {
    collapsed: false,
    openKeys: [],
    selectedKeys: [],
  };

  // 菜单收起
  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  componentDidMount() {
    const {changeTopBarMenu} = this.props.localStore;
    const pathArr = this.props.location.pathname.split("/");
    let key = pathArr[1];
    console.log(key)
    let item = routers.find(item => item.key === key)

    if (this.props.commonStore.userRights.isLoaded) {
      item = {
        ...item,
        children: item.children ? this.getRouteItem(item.children, this.props.menuKeys) : [],
      }
      const currRoute = item.children[0].children[0];
      if (pathArr.length === 2 && currRoute) {
        this.props.history.replace(currRoute.route);
      } else {
        this.setMenuKeys(pathArr);
      }
    }

    console.log(item)
    changeTopBarMenu(item)
  }

  componentWillReceiveProps(nextProps) {
    const {isLoaded, permissionKeys} = nextProps.commonStore.userRights;

    // 员工已授权、新建员工未授权
    if ((JSON.stringify(this.props.menuKeys) !== JSON.stringify(nextProps.menuKeys)) || (isLoaded && !permissionKeys.length)) {
      const {changeTopBarMenu, topBarMenu} = nextProps.localStore;
      const routeItem = {
        ...topBarMenu,
        children: topBarMenu.children ? this.getRouteItem(topBarMenu.children, nextProps.menuKeys) : [],
      }
      changeTopBarMenu(routeItem);

      let pathArr = nextProps.location.pathname.split("/");
      const currRoute = routeItem.children[0].children[0];
      if (pathArr.length === 2 && currRoute) {
        nextProps.history.replace(currRoute.route);
        pathArr = currRoute.route.split('/');
      }
      this.setMenuKeys(pathArr);
    }

    if (this.props.location.pathname !== nextProps.location.pathname) {
      const pathArr = nextProps.location.pathname.split("/");
      this.setMenuKeys(pathArr);
    }
  }

  getRouteItem = (routeList = [], menuKeys = []) => {
    const children = [];

    routeList.forEach(item => {
      if (menuKeys.includes(item.routeKey)) {
        const obj = {
          ...item,
          children: item.children ? this.getRouteItem(item.children, menuKeys) : [],
        }
        children.push(obj);
      }
    })
    return children;
  }

  setMenuKeys = ([_, secondKey = '', thirdKey = '', fourKey = '']) => {
    let selectKey = '';
    if (['app-workbench', 'structure', 'setting'].includes(secondKey)) {
      selectKey = fourKey;
    }
    if (secondKey === 'resource') {
      selectKey = `${thirdKey}${fourKey.slice(0, 1).toUpperCase()}${fourKey.slice(1)}`;
    }
    if (secondKey === 'customer') {
      selectKey = `${fourKey}${thirdKey.slice(0, 1).toUpperCase()}${thirdKey.slice(1)}`;
    }

    this.setState({
      openKeys: [thirdKey],
      selectedKeys: [selectKey],
    });
  }

  // 菜单选中
  handleSelect = ({key, path}) => {
    this.setState({
      ...this.state,
      selectedKeys: [key],
    })
    this.props.history.push(path);
  };

  render() {
    const {collapsed, selectedKeys, openKeys} = this.state;
    const {location} = this.props;
    const {topBarMenu} = this.props.localStore;

    return (
      <div className={styles.left_nav}>
        <div className={styles.logo_box}>
          <div className={styles.logo}></div>
        </div>
        {topBarMenu && <Menu
          selectedKeys={selectedKeys}
          openKeys={openKeys}
          onOpenChange={(openKeys) => {
            this.setState({openKeys})
          }}
          mode="inline"
          inlineCollapsed={collapsed}
        >
          {topBarMenu.children && topBarMenu.children.map((item, index) =>
            (!item.disabled) && item.children ? (
              <SubMenu
                key={item.key}
                icon={<img className={styles.left_nav_menu_icon} src={item.icon}/>}
                title={<span className={styles.left_nav_menu_title}>{item.name}</span>}
              >
                {item.children.map((ele, i) => (
                  !ele.disabled && <Menu.Item
                    onClick={() => {
                      this.handleSelect({path: ele.route, key: ele.routeKey});
                    }}
                    key={ele.routeKey}
                  >
                    {ele.name}
                  </Menu.Item>
                ))}
              </SubMenu>
            ) : (
              <Menu.Item key={item.route}>
                <span>{item.name}</span>
              </Menu.Item>
            )
          )}
        </Menu>}
        <Button type="link" className={styles.collapse_btn} onClick={this.toggleCollapsed}>
          {collapsed ? <DoubleRightOutlined/> : "收起"}
        </Button>
      </div>
    );
  }
}

export default LeftNav;
