import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, Table, Button, Tag, Select, Input, message} from "antd";
import {inject, observer} from "mobx-react";
import {toJS} from "mobx"

import {showMoney} from "utils/tools";
import {config} from "config"
import styles from "../marketOperate.module.scss";

import {RangeInput} from 'components'

const {theme, mainHost} = config;
const {Option} = Select;

@inject(...["goodsStore", "commonStore"])
@observer
class AddGoods extends Component {
  state = {
    tab: "10",
    goodsBrand: undefined,
    categoryListLv1: [],
    categoryListLv2: [],
    goodsCategory: [undefined, undefined],
    rangePrice: ['', ''],
    canSearch: true,
    keyword: "",
    sortInfo: {},
    pageNo: 1,
    pageSize: 20,
    data: {
      list: [],
      total: 0
    },
    selectedRows: []
  }

  componentDidMount() {
    this._initCategory();
    this._getData();
    this.searchBrand();
  }

  _initCategory = async () => {
    const {getGoodsCategory} = this.props.commonStore;
    const categoryListLv1 = await getGoodsCategory({parentId: 0})
    await this.setState({
      categoryListLv1
    })
    if (categoryListLv1[0]) {
      const categoryListLv2 = await getGoodsCategory({parentId: categoryListLv1[0].categoryId})
      await this.setState({
        categoryListLv2
      })
    }
  }

  _getData = () => {
    const {canSearch, tab, goodsBrand, goodsCategory, rangePrice, keyword, sortInfo, pageNo, pageSize,} = this.state;
    const {getGoodsList} = this.props.goodsStore;
    if ((!goodsCategory[0]) ^ (!goodsCategory[1])) {
      message.error("请选择正确的商品分类！");
      return;
    }
    if (!canSearch) {
      message.error("请输入正确的价格区间！")
      return;
    }
    console.log("sortInfo=====", sortInfo)
    const sortKeyMap = {
      marketPriceMin: "market_price_min",
      priceMin: "price_min"
    }
    if (canSearch) {
      let params = {
        status: tab,
        brandId: goodsBrand,
        categoryId: goodsCategory[1],
        priceMin: (rangePrice[0] === '0' ? null : rangePrice[0] * 1e2) || undefined,
        priceMax: (rangePrice[1] === '0' ? null : rangePrice[1] * 1e2) || undefined,
        name: keyword || undefined,
        sortField: sortInfo.column ? sortKeyMap[sortInfo.columnKey] : undefined,
        sortOrder: sortInfo.column ? (sortInfo.order && sortInfo.order.slice(0, -3)) : undefined,
        pageNo,
        pageSize,
      };
      console.log(params);
      getGoodsList(params)

    }
  }

  renderTable = () => {
    const {items, type, onSelect} = this.props;
    const {pageNo, sortInfo} = this.state;
    let data = this.props.goodsStore.goodsList

    const columns = [
      {
        title: '标题',
        dataIndex: 'name',
        key: 'name',
        width: 348,
        render: (text, record) =>
          <div className={styles.goods_name_container}>
            <img src={record.mainImage} className={styles.goods_img} alt=""/>
            <div className={styles.goods_name_right}>
              <p className={styles.name}>{text}</p>
              {+record.isSupportOnce === 1 && <Tag color="processing">一件代发</Tag>}
            </div>
          </div>,
      }, {
        title: '市场价',
        dataIndex: 'marketPriceMin',
        key: 'marketPriceMin',
        sorter: true,
        sortOrder: sortInfo.columnKey === 'marketPriceMin' && sortInfo.order,
        ellipsis: true,
        width: 150,
        render: (text) => <div>{text ? `¥${showMoney(text)}` : "-"}</div>
      }, {
        title: '销售价',
        dataIndex: 'priceMin',
        key: 'priceMin',
        sorter: true,
        sortOrder: sortInfo.columnKey === 'priceMin' && sortInfo.order,
        ellipsis: true,
        width: 150,
        render: (text, record) => {
          let res = null;
          switch (+record.salesType) {
            case 1:
              res = record.stairList ? <div>{
                record.stairList.map((itemIn, indexIn, arrIn) => {
                  if (indexIn === arrIn.length - 1) {
                    return <p key={indexIn}>{`¥${showMoney(itemIn.price)} (≥${itemIn.minNum}${record.unit || "个"})`}</p>
                  } else {
                    return <p
                      key={indexIn}>{`¥${showMoney(itemIn.price)} (${itemIn.minNum}-${itemIn.maxNum}${record.unit || "个"})`}<br/>
                    </p>
                  }
                })
              }</div> : "-"
              break;
            case 2:
              res = text ? <div>¥{showMoney(text)}</div> : "-"
              break;
          }
          return res
        }
      }, {
        title: "操作",
        align: "center",
        fixed: "right",
        render: (text, record) => items.map(item => item.goodsId).includes(record.goodsId) ?
          <Button disabled style={{width: 80}}>已选择</Button> :
          <Button style={{width: 80}} onClick={() => onSelect([record], type === "module" && type)}>选择</Button>
      }]
    const pagination = {
      total: data.total,
      current: pageNo,
      showSizeChanger: true,
      showQuickJumper: true,
      pageSizeOptions: ["20", "50", "100"],
      defaultPageSize: 20,
      onChange: this.changePage,
      onShowSizeChange: this.changePageSize,
      showTotal: total => `共 ${total} 条数据`,
    }
    const rowSelection = {
      columnWidth: 60,
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          selectedRows
        })
      },
      getCheckboxProps: record => ({
        disabled: items.map(item => item.goodsId).includes(record.goodsId),
        name: record.name,
      }),
    }
    return <Table columns={columns}
                  size="small"
                  dataSource={data.list}
                  pagination={pagination}
                  rowSelection={type === "module" && rowSelection}
                  onChange={this.tableChange}
                  rowKey={record => record.key}/>
  }

  /**
   * 页码改变
   * @param page
   * @param pageSize
   */
  changePage = (page, pageSize) => {
    this.setState({
      pageNo: page,
    }, this._getData)
  }

  /**
   * pageSize改变
   * @param current
   * @param size
   */
  changePageSize = (current, size) => {
    this.setState({
      pageNo: 1,
      pageSize: size,
    }, this._getData)
  }
  /**
   * 排序变化
   * @param pagination
   * @param filters
   * @param sorter
   */
  tableChange = (pagination, filters, sorter) => {
    console.log(sorter)
    if (sorter)
      this.setState({
        pageNo: 1,
        sortInfo: sorter
      }, this._getData)
  }

  render() {
    const {visible, type, onCancel, onSelect} = this.props;
    const {tab, goodsBrand, categoryListLv1, categoryListLv2, goodsCategory, rangePrice, keyword, selectedRows} = this.state
    const {brandList} = this.props.commonStore;
    console.log(toJS(brandList))
    return (
      <Modal visible={visible}
             centered
             title={type === "single" ? "选择商品" : "添加商品"}
             onCancel={onCancel}
             footer={null}
             width={1000}
             zIndex={1031}>
        <div style={{
          maxHeight: 600,
          overflowY: "auto",
        }}>
          <div className={styles.screen_container}>
            <div className={styles.left}>
            </div>
            <div className={styles.right}>
              <Select allowClear
                      size="small"
                      placeholder="品牌"
                      value={goodsBrand}
                      onChange={this.changeBrand}
                // showSearch
                // defaultActiveFirstOption={false}
                // showArrow={true}
                // filterOption={false}
                // onSearch={this.searchBrand}
                // notFoundContent={null}
                      style={{
                        minWidth: theme.distances["basic-gap"] * 13,
                        marginRight: theme.distances["basic-gap"]
                      }}>
                {toJS(brandList).map(item => <Select.Option key={item.brandId}
                                                            value={item.brandId}>{item.brandName}</Select.Option>)}
              </Select>
              <Select allowClear
                      size="small"
                      placeholder="一级分类"
                      value={goodsCategory[0]}
                      style={{
                        minWidth: theme.distances["basic-gap"] * 12,
                        marginRight: theme.distances["basic-gap"]
                      }}
                      onChange={val => this.changeCategory(val, 0)}>
                {categoryListLv1.map(item =>
                  <Option key={item.categoryId} value={item.categoryId}>{item.categoryName}</Option>)}
              </Select>
              <Select allowClear
                      size="small"
                      placeholder="二级分类"
                      value={goodsCategory[1]}
                      style={{
                        minWidth: theme.distances["basic-gap"] * 12,
                        marginRight: theme.distances["basic-gap"] * 2
                      }}
                      onChange={val => this.changeCategory(val, 1)}>
                {categoryListLv2.map(item =>
                  <Option key={item.categoryId} value={item.categoryId}>{item.categoryName}</Option>)}
              </Select>
              <RangeInput addonAfter="¥"
                          size="small"
                          name="销售价"
                          allowClear={false}
                          value={rangePrice}
                          onChange={this.changePriceRange}
                          style={{width: theme.distances["basic-gap"] * 16}}/>

              <Input placeholder="请输入商品名称"
                     size="small"
                     allowClear
                     value={keyword}
                     onChange={this.changeKeyword}
                     style={{
                       width: theme.distances["basic-gap"] * 18,
                       marginLeft: theme.distances["basic-gap"] * 2,
                       marginRight: theme.distances["basic-gap"]
                     }}/>
              <Button type="primary"
                      size="small"
                      style={{marginRight: theme.distances["basic-gap"]}}
                      onClick={this.search}>查询</Button>
              <Button type="default"
                      size="small"
                      onClick={this.resetSearch}>重置搜索</Button>
            </div>
          </div>
          {this.renderTable()}
        </div>
        {type === "module" &&
        <div className={styles.modal_footer}>
          <Button type="primary" onClick={() => onSelect(selectedRows, type === "module" && type)}>选择</Button>
        </div>}
      </Modal>
    );
  }

  /**
   * 改变品牌
   * @param val
   */
  changeBrand = val => {
    this.setState({
      goodsBrand: val
    })
  }

  /**
   * 获取品牌列表
   */
  searchBrand = (val) => {
    const {getGoodsBrand} = this.props.commonStore;
    if (this.throttleTimer) clearTimeout(this.throttleTimer)
    this.throttleTimer = setTimeout(() => {
      let params = {
        keyword: val,
        pageNo: 1,
        pageSize: 100
      };
      getGoodsBrand(params)
    }, 500)
  }

  /**
   * 改变分类
   * @param val
   */
  changeCategory = (val, type) => {
    const {getGoodsCategory} = this.props.commonStore;
    const {goodsCategory} = this.state;
    let arr = goodsCategory
    arr[type] = val;
    if (+type === 0) arr[1] = undefined
    this.setState({
      goodsCategory: arr
    }, async () => {
      if (+type === 0 && val) {
        const categoryListLv2 = await getGoodsCategory({parentId: val})
        this.setState({
          categoryListLv2
        })
      }
    })
  }

  /**
   * 销售价改变
   * @param val
   * @param bool
   */
  changePriceRange = (val, bool) => {
    this.setState({
      rangePrice: val,
      canSearch: bool
    })
  }

  /**
   * 商品名称/货号
   * @param e
   */
  changeKeyword = e => {
    let val = e.target.value.trim();
    this.setState({
      keyword: val
    })
  }

  /**
   * 查询
   */
  search = () => {
    this.setState({
      pageNo: 1
    }, this._getData)
  }

  /**
   * 重置
   */
  resetSearch = () => {
    this.setState({
      goodsBrand: undefined,
      goodsCategory: [undefined, undefined],
      rangePrice: ['', ''],
      canSearch: true,
      keyword: "",
      sortInfo: {},
      pageNo: 1,
    }, this._getData)
  }
}

AddGoods.propTypes = {
  visible: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(["single", "module"]),
  items: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSelect: PropTypes.func
};

export default AddGoods;
