import { action, observable } from 'mobx'
import httpClient from '../../utils/httpClient';
import api from '../../config/api';

const { home } = api;

class HomeStore {
  /**
   * 添加客户线索
   * @param params
   * @returns {Promise<void>}
   */
  @action
  addCustomerClue = async params => {
    const res = await httpClient.post(home.POST_ADD_CUSTOMER_CLUE, { ...params });
    return res;
  }

  /**
   * 添加官网PV
   * @param params
   * @returns {Promise<void>}
   */
  @action
  addHomePv = async params => {
    const res = await httpClient.post(home.POST_ADD_HOME_PV, { ...params });
    return res;
  }
}

export default new HomeStore();
