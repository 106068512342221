import React from 'react'
import PropTypes from "prop-types"
import styles from './index.module.scss'

function Footer(props) {
  const {type} = props

  let footer = null;
  switch (type) {
    case "main":
      footer = <div></div>
      break;
    case "login":
      footer = <div className={styles.footer_container}>
        <span className={styles.footer_text}>footer</span>
      </div>
      break;
    default:
      footer = <div></div>
  }
  return footer;
}

Footer.propTypes = {
  type: PropTypes.oneOf(['main', "login"]).isRequired
}

export default Footer;
