import Loading from './Loading'

import Bread from './Bread'
import Footer from "./Footer"
import TopBar from './TopBar'
import LeftNav from './LeftNav'

import CheckTags from './CheckTags';
import ScrollImgs from './ScrollImgs';
import ModuleTitle from './ModuleTitle'
import RangeInput from './RangeInput'
import PageMenu from './PageMenu'
import Order from "./Order"
import Adder from "./Adder"
import ImgPreview from './ImgPreview'
import Floating from './Floating'
import GuideCover from './GuideCover'
import PreviewPDF from './PreviewPDF'
//ListItem

//Modal
import AddGoodsCustomItemsModal from './Modal/AddGoodsCustomItems'
import AddGoodsChangePriceModal from './Modal/AddGoodsChangePrice'
import MarketOperateAddMicro from "./Modal/MarketOperateAddMicro"
import MarketOperateAddCategory from "./Modal/MarketOperateAddCategory"
import MarketOperateAddGoods from "./Modal/MarketOperateAddGoods"
import SettingPurchaseModal from "./Modal/SettingPurchase"

import MarketOperateActivityDetailModal from './Modal/MarketOperateActivityDetail'
import MarketOperateActivityDataModal from './Modal/MarketOperateActivityData'
import MarketOperateActivityInputModal from './Modal/MarketOperateActivityInput'
import ActivityDetailPreviewModal from  "./Modal/MarketOperateActivityDetailPreview"

import MarketOperateMessageSelect from './Modal/MarketOperateMessageSelect'
import BatchUploadGoodsModal from "./Modal/BatchUploadGoods"

//Module
import PhoneHeader from "./Module/PhoneHeader"
import ModuleSwiper from './Module/ModuleSwiper'
import ModuleBanner from './Module/ModuleBanner'
import ModuleGoods from './Module/ModuleGoods'
import ModuleNav from './Module/ModuleNav'

const Layout = {
  Bread, Footer, TopBar, LeftNav
}
const ListItem = {},
  Modals = {
    AddGoodsCustomItemsModal,
    AddGoodsChangePriceModal,
    MarketOperateAddMicro,
    MarketOperateAddCategory,
    MarketOperateAddGoods,
    SettingPurchaseModal,
    MarketOperateActivityDetailModal,
    MarketOperateActivityDataModal,
    MarketOperateActivityInputModal,
    ActivityDetailPreviewModal,
    MarketOperateMessageSelect,
    BatchUploadGoodsModal
  },
  Modules = {PhoneHeader, ModuleSwiper, ModuleBanner, ModuleGoods, ModuleNav}


export {
  Loading,
  CheckTags, ScrollImgs, ModuleTitle, RangeInput, PageMenu, Order,
  Layout, ListItem, Modals, Modules, Adder, ImgPreview,Floating,GuideCover,PreviewPDF
}
