import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Steps, Form, Button, Input } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import QRCode from 'qrcode-react'

import {Layout} from "components";
import commonStore from 'stores/common';
import './register.scss';

const md5 = require('md5');
const { TopBar } = Layout;
const { Step } = Steps;

function Register(props) {
  const [currentStep, setCurrentStep] = useState(0);
  const [accountFlag, setAccountFlag] = useState(2); // 账号标识，0-不可用，1-可用，2-未测试
  const [codeState, setCodeState] = useState(0); // 0-获取验证码 1-倒计时 2-重新获取
  const [second, setSecond] = useState(60);
  const [isCodeLoading, setIsCodeLoading] = useState(false);
  const [codeInterval, setCodeInterval] = useState(null);
  const [qrcode, setQrcode] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [registerForm] = Form.useForm();

  const customDot = (dot, { status, index }) => (
    <span>{index + 1}</span>
  )

  const checkUserName = () => {
		registerForm.validateFields().then(async (values) => {
      const { account } = values;
      const res = await commonStore.checkUserName({
        companyAccount: account,
      });
      setAccountFlag(res.data ? 1 : 0);
    })
	}

  const codeBtnDom = () => {
		return codeState === 0 ? '获取验证码' : codeState === 1 ? `${second}s` : '重新获取'
	}

  const tick = () => {
    setSecond((prevSecond) => {
      if (prevSecond === 0) {
        clearInterval(codeInterval);
        setCodeInterval(null);
        setCodeState(2);
        return 60;
      }
      return prevSecond - 1;
    })
	}

  const getValidateCode = () => {
    if (codeState === 1) return false;
    const { mobile } = registerForm.getFieldsValue();
    if (mobile) {
      setIsCodeLoading(true);

      commonStore.getSmsCode({
        mobile,
      }).then((res) => {
        if (res.code === '2000') {
          setCodeState(1);
          setSecond(60);
          setIsCodeLoading(false);
          clearInterval(codeInterval);
          setCodeInterval(setInterval(tick, 1000));
        } else {
          setIsCodeLoading(false);
        }
      }).catch((err) => {
        setIsCodeLoading(false);
      })
    }
	}

  const submit = async () => {
		registerForm.validateFields().then(async (values) => {
      const res = await commonStore.postRegister({
        ...values,
        loginPwd: md5(values.loginPwd),
				confirmPwd: md5(values.confirmPwd),
      })

			if (res.success) {
        setCompanyName(values.name);
        setCurrentStep(2);
        const qrRes = await commonStore.getAuthQrcode({ authType: 2 });
        setQrcode(qrRes.data);
      }
		})
	}

  const goLogin = () => {
    props.history.push('/login');
  }

  const goWorkbench = () => {
    window.location.href = "/workbench";
  }

  return (
    <div className="register-wrap">
      <TopBar type="login" page="register"/>

      <div className="register-box">
        <Steps current={currentStep} labelPlacement="vertical" progressDot={customDot}>
          <Step title="注册" />
          <Step title="公司基础资料" />
          <Step title="完成" />
        </Steps>

        <Form form={registerForm} className="register-form">
          {currentStep !== 2 && (
            <>
              <Form.Item className="account-form-item" extra={accountFlag == 0 ? '该账户名已被注册，请重新填写' : ''}>
                <Form.Item
                  name="account"
                  rules={[
                    { required: true, message: '账户名不可为空' },
                    { min: 4, max: 20, message: '长度只能在4-20个字符之间' },
                    { pattern: /^[\u4e00-\u9fa5a-zA-Z0-9]+$/, message: '仅支持中英文及数字' },
                  ]}
                  validateTrigger="onBlur"
                  noStyle
                >
                  <Input className="register-form-input" minLength={4} onChange={() => accountFlag !== 2 && setAccountFlag(2)} maxLength={20} prefix="账&nbsp;&nbsp;户&nbsp;&nbsp;名" placeholder="将作为登录账号，建议填写公司简称" />
                </Form.Item>
                {accountFlag === 0
                  ? <p type="link" className="check-res no"><CloseOutlined style={{ marginRight: 5 }} />账户不可用</p>
                  : accountFlag === 1 ? <p type="link" className="check-res ok"><CheckOutlined style={{ marginRight: 5 }} />账户可用</p>
                  : accountFlag === 2 ? <Button onClick={checkUserName} size='large' className="check-btn">可用校验</Button>
                  : ''
                }
              </Form.Item>
              {currentStep === 0 && <Button type="danger" className="form-ok-btn" disabled={accountFlag !== 1} onClick={() => setCurrentStep(1)}>确 定</Button>}
            </>
          )}

          {currentStep === 1 && accountFlag === 1 && (
            <>
              <Form.Item
                name="mobile"
                validateTrigger="onBlur"
                getValueFromEvent={event => event.target.value.replace(/\D/g, '')}
                rules={[
                  { required: true, message: '手机号不可为空' },
                  { pattern: /^1\d{10}$/, len: 11, message: '手机号格式错误' },
                ]}
              >
                <Input className="register-form-input" maxLength={11} prefix="手&nbsp;&nbsp;机&nbsp;&nbsp;号" placeholder="可用于登录及账号设置" />
              </Form.Item>
              <Form.Item>
                <Form.Item
                  name="smsCode"
                  validateTrigger="onBlur"
                  rules={[{ required: true, message: '验证码不可为空' }]}
                  noStyle
                >
                  <Input className="register-form-input code-input" maxLength={10} prefix="验&nbsp;&nbsp;证&nbsp;&nbsp;码" placeholder="请输入验证码" />
                </Form.Item>
                <Button type="danger" disabled={codeState === 1} className="code-btn" onClick={getValidateCode} loading={isCodeLoading} >
                  {codeBtnDom()}
                </Button>
              </Form.Item>
              <Form.Item
                name="loginPwd"
                getValueFromEvent={event => event.target.value.replace(' ', '').replace(/[\u4e00-\u9fa5]/g, '')}
                validateTrigger="onBlur"
                rules={[
                  { required: true, message: '登录密码不可为空' },
                  { min: 8, max: 20, message: '长度只能在8-20个字符之间' },
                  { pattern: /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{8,20}$/, message: '字母、数字、字符其中至少两种' },
                ]}
              >
                <Input className="register-form-input" prefix="设&nbsp;置&nbsp;密&nbsp;码" type="password" placeholder="请设置登录密码" />
              </Form.Item>
              <Form.Item
                name="confirmPwd"
                validateTrigger="onBlur"
                validateFirst={true}
                getValueFromEvent={event => event.target.value.replace(' ', '').replace(/[\u4e00-\u9fa5]/g, '')}
                rules={[
                  { required: true, message: '登录密码不可为空' },
                  { min: 8, max: 20, message: '长度只能在8-20个字符之间' },
                  { pattern: /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{8,20}$/, message: '字母、数字、字符其中至少两种' },
                  {
                    validator: (rule, value, callback) => {
                      if (value !== registerForm.getFieldValue('loginPwd')) {
                        callback('两次输入密码不一致')
                      } else {
                        callback()
                      }
                    }
                  },
                ]}
              >
                <Input className="register-form-input" prefix="确&nbsp;认&nbsp;密&nbsp;码" type="password" placeholder="请再次输入登录密码" />
              </Form.Item>
              <Form.Item
                name="name"
                validateTrigger="onBlur"
                rules={[
                  { required: true, message: '公司名称不可为空' },
                  { min: 4, max: 20, message: '长度只能在4-20个字符之间' },
                ]}
              >
                <Input className="register-form-input" minLength={4} maxLength={20} prefix="公&nbsp;司&nbsp;名&nbsp;称" placeholder="请填写公司名称" />
              </Form.Item>
              <Button className="form-ok-btn" type="danger" onClick={submit}>提 交</Button>
            </>
          )}

          {
            currentStep === 2 && (
              <div className="register-success">
                <div className="success-tips">已为您创建“{companyName}”工作台<br/>您可立即打开微信扫描下方二维码授权，创建手机端专属小程序工作台</div>
                <QRCode value={qrcode} size={214} className="qr-code" />
                <Button
                  type="primary"
                  size="large"
                  onClick={goWorkbench}
                  style={{ background: '#ff440a', borderColor: '#ff440a', marginTop: 16 }}
                >立即进入企业PC工作台</Button>
              </div>
            )
          }
        </Form>
      </div>
    </div>
  )
}

export default observer(Register);
