import {action, observable} from 'mobx'


class LocalStore {

  @observable topBarMenu = {}
  @observable breadList = [];
  @observable guideShow = false


  /**
   * 页面顶部menu点击
   * @param params
   * @returns {Promise<void>}
   */
  @action
  changeTopBarMenu = async params => {
    this.topBarMenu = params
    console.log(this.topBarMenu)
  }

  /**
   * 页面面包屑
   * @param params
   * @returns {Promise<void>}
   */
  @action
  changeBreadList = params => {
    this.breadList = params;
  }

  /**
   * 设置新手导航显示
   * @param params
   */
  @action
  setGuideShow = params => {
    this.guideShow = params
  }
}

export default new LocalStore();
