import React from 'react';
import PropTypes from 'prop-types';
import {CloseCircleFilled} from "@ant-design/icons"


import GoodsItem from '../../ListItem/AppGoodsItem'
import styles from '../index.module.scss'

function ModuleGoods(props) {
  const {list, onClick, onCancel} = props;
  return (
    <div className={styles.module_goods_container} onClick={onClick}>
      {list.map((item, index) =>
        <GoodsItem item={item} key={index} style={{
          marginRight: index % 2 === 0 ? 4 : 0,
          marginBottom: 8
        }}/>
      )}
      <div className={styles.module_cancel} onClick={e=>{
        e.stopPropagation();
        onCancel()
      }}><CloseCircleFilled/></div>
    </div>
  );
}

ModuleGoods.propTypes = {
  list: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  onCancel: PropTypes.func
};

export default ModuleGoods;
