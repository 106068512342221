import React from 'react';
import PropTypes from 'prop-types';

import {showMoney} from "utils/tools";
import styles from './index.module.scss'

function AppGoodsItem(props) {
  const {item,style} = props
  return (
    <div className={styles.item_container} style={style}>
      <img src={item.mainImg} alt="" className={styles.item_image}/>
      <div className={styles.item_content}>
        <p className={styles.item_name}>{item.name}</p>
        <p className={styles.item_price}>
          <span className={styles.price_title}>销售价：</span>{`¥${showMoney(item.price)}`}</p>
      </div>
    </div>
  );
}

AppGoodsItem.propTypes = {
  item: PropTypes.object.isRequired
};

export default AppGoodsItem;
