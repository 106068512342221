import React from 'react';
import PropTypes from 'prop-types';
import {CloseCircleFilled} from '@ant-design/icons'

import styles from '../index.module.scss'

function ModuleBanner(props) {
  const {list, onClick, onCancel} = props
  return (
    <div className={styles.module_container} onClick={onClick}>
      {list.map((item, index) =>
        <img src={item.imageUrl} alt="" key={index} style={{width: "100%"}}/>)}
      <div className={styles.module_cancel} onClick={e => {
        e.stopPropagation();
        onCancel()
      }}><CloseCircleFilled/></div>
    </div>
  );

}

ModuleBanner.propTypes = {
  list: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  onCancel: PropTypes.func
};

export default ModuleBanner;
