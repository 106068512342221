const api = {
  // 通用接口
  common: {
    POST_SMS_CODE: "/basic/sms/sms-code",//获取短信验证码
    POST_LOGIN: "/gift/right/login/login/telephone",//登录
    POST_LOGIN_NEW: "/gift/right/login/login", // 登录
    POST_REGISTER: "/member/company/signup",//注册
    POST_REGISTER_NEW: "/elebuys/member/company/signup",//注册
    POST_CHECK_RESET_ACCOUNT: "/gift/right/login/forgetPwdStep1", // 忘记密码第一步
    POST_RESET_PASSWORD: "/gift/right/login/forgetPwdStep2", // 忘记密码第二步
    GET_CHECK_USER_NAME: "/elebuys/member/company/account/availability", // 校验用户名
    GET_AUTH_QRCODE: "/gift/wechat/miniapp/authDirectUrl",//获取授权二维码
    POST_LOGOUT: "/gift/right/login/logout",//退出
    POST_UPLOAD_FILE: "/basic/file/upload", // 上传文件
    POST_GET_USER_RIGHTS: "/gift/right/menu/list", // 获取用户权限列表
    GET_SYS_DICT: "/basic/dict/dictionaries",//系统字典（bestgiftIndustryType 所属行业，企业类型列表）
    GET_GOODS_CATEGORY: "/gift/goods/category/subList",//获取商品类型
    GET_GOODS_BRAND: "/gift/goods/brand/list",//获取商品品牌
    GET_GOODS_BRAND_BY_ID: "/gift/goods/brand/get",//通过id获取品牌
    GET_AREAS: "/basic/area/areas", // 获取省市信息
    GET_MEMBER_STATISTICS: "/gift/pc/member/getPCMemberStatistics", // 获取工作台统计数量
    GET_MINIPROGRAM_STATUS:'/gift/wechat/miniapp/getAppPrincipal',//获取小程序状态
    GET_FIRST_LOGIN_STATUS:"/basic/showTip/getStatus",//获取用户是否第一次登录状态
  },
  home: {
    POST_ADD_CUSTOMER_CLUE: "/gift/official-website/customer-lead/add", // 新增客户线索
    POST_ADD_HOME_PV: "/gift/official-website/official-website-stat/add", // 新增官网PV
  },
  goods: {
    POST_GET_GOODS_LIST_STATUS: "/gift/pc/goods/company-option/getOption",//获取企业配置
    POST_SET_GOODS_LIST_STATUS: "/gift/pc/goods/company-option/updateGoodsOpen",//设置企业配置
    GET_GOODS_LIST: "/gift/pc/goods/goods-query/getGoodsList",//获取商品列表
    POST_GET_CUSTOMER_LEVEL: "/gift/pc/goods/customer-level/getList",//获取客户等级列表（设置价格）
    POST_UPLOAD_GOODS: "/gift/pc/goods/goods/importGoods",//商品批量导入
    POST_ADD_GOODS: "/gift/pc/goods/goods/add",// 商品录入
    POST_EDIT_GOODS: "/gift/pc/goods/goods/edit",// 商品编辑
    POST_GET_GOODS_DETAIL: "/gift/pc/goods/goods/getEditInfo",//获取商品详情
    POST_GET_GOODS_CATEGORY_TREE: "/gift/goods/category/tree", // 获取商品分类树
    POST_GET_GOODS_CATEGORY_MANAGE_TREE: "/gift/goods/category/manageTree", // 获取商品分类树(权限)
    POST_ADD_GOODS_CATEGORY: "/gift/goods/category/add", // 添加商品分类
    POST_UPDATE_GOODS_CATEGORY: "/gift/goods/category/update", // 编辑商品分类
    POST_DELETE_GOODS_CATEGORY: "/gift/goods/category/cancel", // 删除商品分类
    POST_GET_CATEGORY_GOODS_COUNT: "/gift/goods/category/getCategoryGoodsCount", // 获取商品分类关联的商品数
    POST_GET_GOODS_BRAND_LIST: "/gift/goods/brand/list", // 获取商品品牌列表
    POST_GET_GOODS_BRAND_MANAGE_LIST: "/gift/goods/brand/manageList", // 获取商品品牌列表(权限)
    POST_ADD_GOODS_BRAND: "/gift/goods/brand/add", // 添加商品品牌
    POST_UPDATE_GOODS_BRAND: "/gift/goods/brand/update", // 编辑商品品牌
    POST_DELETE_GOODS_BRAND: "/gift/goods/brand/cancel", // 删除商品品牌
    POST_GET_BRAND_GOODS_COUNT: "/gift/goods/brand/getBrandGoodsCount", // 获取品牌关联商品数
    POST_UP_SHELF_GOODS: "/gift/pc/goods/goods/onlineShelf",//上架商品
    POST_DOWN_SHELF_GOODS: "/gift/pc/goods/goods/offShelf",//下架商品
    POST_DEL_GOODS: "/gift/pc/goods/goods/remove",//删除商品
    POST_GET_SELLING_POINT_LIST: "/gift/pc/goods/goods-slogan/getCommonList",//获取商品卖点列表
  },
  scheme: {
    POST_GET_PUBLIC_SCHEME_LIST: "/gift/pc/scheme/common/list",//获取公共方案列表
    POST_ADD_PUBLIC_SCHEME: "/gift/pc/scheme/common/add",//添加公共方案
    POST_PUBLIC_SCHEME_ACTION: "/gift/pc/scheme/common/change-status",//公共方案操作
  },
  applet: {
    GET_HOME_DETAIL: "/gift/pc/micro-page/mall-index",//获取首页详情
    POST_HOME_ADD_EDIT: "/gift/pc/micro-page/mallAddOrUpdate",//商城首页添加、编辑 与微页面编辑
    POST_MICRO_EDIT: "/gift/pc/micro-page/update",//微页面编辑
    POST_ADD_MODULE: "/gift/pc/micro-page/add-module",//添加模块
    POST_DEL_MODULE: "/gift/pc/micro-page/delete-module",//删除模块
    POST_EDIT_MODULE: "/gift/pc/micro-page/update-module",//编辑模块

    POST_GET_MICROPAGE_LIST: "/gift/pc/micro-page/list", // 获取微页面列表
    POST_ADD_MICROPAGE: "/gift/pc/micro-page/add", // 添加微页面
    POST_GET_HOT_GOODS_LIST: "/gift/pc/goods/hot-recommend-goods/list", // 获取爆品推荐列表
    POST_GET_ADD_GOODS_LIST: "/gift/pc/goods/hot-recommend-goods/select-list", // 获取爆品推荐-添加商品列表
    POST_ADD_HOT_GOODS: "/gift/pc/goods/hot-recommend-goods/add", // 添加爆品推荐
    POST_DELETE_HOT_GOODS: "/gift/pc/goods/hot-recommend-goods/delete", // 删除爆品
    GET_MICROPAGE_DETAIL: "/gift/pc/micro-page/detail", // 获取微页面详情

    GET_APPLET_INFO: "/gift/wechat/miniapp/getAppPrincipal", // 获取授权主体信息
    GET_APPLET_AUTH_URL: "/gift/wechat/miniapp/authUrl", // 获取授权页面地址
    GET_APPLET_BETA_URL: "/gift/wechat/miniapp/betaMatrix", // 获取体验版二维码

    POST_GET_MARKETING_ACTIVITY_LIST: "/gift/activity/queryPage",//查询营销活动列表
    POST_CREATE_MARKETING_ACTIVITY: "/gift/activity/create",//创建营销活动
    POST_UPDATE_MARKETING_ACTIVITY: "/gift/activity/update",//编辑营销活动
    GET_MARKETING_ACTIVITY_BY_ID: "/gift/activity/get",//通过id查询单个营销活动
    POST_GET_MARKETING_ACTIVITY_DATA: "/gift/activity/people/queryPage",//营销活动报名数据
    GET_DOWNLOAD_ACTIVITY_DATA: "/gift/activity/people/exportPeople",//导出活动报名数据
    GET_DOWNLOAD_RES_INPUT_MODEL:"",//下载导入结果模板
    POST_UPLOAD_RES_DATA:"/gift/activity/award/import",//导入获奖名单
    GET_RES_AWARD_LIST:"/gift/activity/award/list",//获取获奖名单

    POST_GET_ARTICLE_LIST: "/gift/article/queryPage",//查询文章列表
    POST_CREATE_ARTICLE: "/gift/article/create",//新建文章
    POST_UPDATE_ARTICLE: "/gift/article/update",//编辑文章
    GET_ARTICLE_BY_ID: "/gift/article/get",//通过id查询单个文章
    POST_GET_SIMPLE_ARTICLE_LIST:"/gift/article/list",//获取简单文章列表

    POST_GET_MESSAGE_LIST: "/gift/pc/message/list",//查询消息列表
    POST_CREATE_MESSAGE: "/gift/pc/message/add",//新建消息
    POST_MESSAGE_ACTION:"/gift/pc/message/update-status",//更改消息状态
  },
  role: {
    GET_ROLE_LIST: "/gift/right/role/list", // 获取角色列表
    GET_PERMISSION_LIST: "/gift/right/menu/tree", // 获取权限列表
    POST_ADD_ROLE: "/gift/right/role/add", // 添加角色
    POST_UPDATE_ROLE: "/gift/right/role/update", // 编辑角色
    POST_ROLE_AUTH: "/gift/right/rolemenu/assign", // 角色授权
  },
  employee: {
    GET_COMPANY_ACCOUNT: "/elebuys/member/employee/company-info", // 获取公司账号
    GET_DEPARTMENT_LIST: "/member/department/tree", // 获取部门列表
    GET_EMPLOYEE_LIST_BY_COMPANY: "/member/employee/employees/search", // 获取公司员工列表
    GET_EMPLOYEE_LIST_BY_DEPARTMENT: "/member/employee/employees/department", // 获取部门员工列表
    GET_CUSTOMER_BY_EMPLOYEE: "/member/employee/charge-customers", // 获取员工归属客户
    GET_EMPLOYEE_BY_COMPANY: "/member/employee/employee-names", // 获取公司所有员工信息
    POST_ADD_DEPARTMENT: "/member/department/sub-dept", // 添加子部门
    POST_UPDATE_DEPARTMENT: "/member/department/name/update", // 编辑部门
    POST_ADD_EMPLOYEE: "/elebuys/member/employee/employee", // 添加员工
    POST_EMPLOYEE_AUTH_ROLE: "/gift/right/userrole/assign", // 员工授权角色
    PUT_UPDATE_EMPLOYEE: "/elebuys/member/employee/update", // 编辑员工
    DELETE_DEPARTMENT: "/member/department/delete", // 删除部门
    DELETE_EMPLOYEE: "/member/employee/delete", // 删除员工
  },
  customer: {
    GET_COMPANY_CUSTOMER: "/member/customer/company-customers", // 公司客户
    GET_CUSTOMER_BY_COMPANY: "/member/customer/customers", // 我的客户
    GET_COMPANY_CONTACTS_LIST: "/member/customer/company-contacts", // 获取公司联系人列表
    GET_MY_CONTACTS_LIST: "/member/customer/contacts/query", // 获取我的联系人列表
    GET_CUSTOMER_NAME_LIST: "/member/customer/customer-names", // 获取公司客户名称
    GET_CUSTOMER_AUDIT_LIST: "/member/customer/customer-level/audits", // 获取客户等级审核列表
    GET_AUDIT_COUNT: "/member/customer/customer-level/wait-audit/count/", // 获取等级调整总数
    GET_CUSTOMER_LEVEL_LIST: "/member/customer/levels", // 客户等级列表
    GET_CUSTOMER_TAG_LIST: "/member/customer/labels", // 客户标签列表
    GET_COMPANY_POTENTIAL_CUSTOMER_LIST: "/member/customer/company-potential-customers", // 查询公司潜在客户
    GET_POTENTIAL_CUSTOMER_LIST: "/member/customer/potential-customers", // 查询潜在客户
    POST_ADD_COMPANY_CUSTOMER: "/member/customer/company-customer", // 添加公司客户
    POST_ADD_CUSTOMER: "/member/customer/customer/add", // 添加客户
    POST_ADD_CUSTOMER_LEVEL: "/member/customer/level", // 添加客户等级
    POST_ADD_CUSTOMER_TAG: "/member/customer/label", // 添加客户标签
    POST_ADD_CONTACTS: "/member/customer/contacts/add", // 添加联系人
    POST_ADD_AS_CONTACTS: "/member/customer/potential/contacts", // 添加为联系人
    POST_CHANGE_POTENTIAL_CUSTOMER_OWNER: "/member/customer/potential-customer/belongs/change", // 变更潜在客户归属
    PUT_UPDATE_COMPANY_CUSTOMER: "/member/customer/company-customer", // 编辑客户
    PUT_UPDATE_CUSTOMER: "/member/customer/customer/update", // 编辑客户
    PUT_CHANGE_CUSTOMER_OWNER: "/member/customer/customer/belongs", // 变更客户归属员工
    PUT_UPDATE_CUSTOMER_LEVEL: "/member/customer/level", // 编辑客户等级
    PUT_UPDATE_CUSTOMER_TAG: "/member/customer/label", // 编辑客户标签
    PUT_UPDATE_CONTACTS: "/member/customer/contacts/update", // 编辑联系人
    PUT_AUDIT_OPS: "/member/customer/customer-level/audit", // 通过、驳回客户等级调整
    DELETE_CUSTOMER_TAG: "/member/customer/label", // 删除客户标签
  },
  setting: {
    GET_PRODUCT_LIST: "/member/product/product-versions",//获取付费产品列表
    GET_USING_PRODUCT_DETAIL: "/member/product/company-using-product",//获取正在使用的产品
    GET_PURCHASE_RECORDS: "/member/product/my-buy-records",//购买记录
  }
}

export default api;
