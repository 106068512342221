import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import Swiper from 'swiper';
import { Form, Button, Input, Modal } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import homeStore from 'stores/home';

import 'swiper/css/swiper.min.css'
import styles from "./index.module.scss"

const tabList = [
  {
    title: '客户管理',
    key: 'customer',
  },
  {
    title: '销售管理',
    key: 'sale',
  },
  {
    title: '市场营销',
    key: 'market',
  }
]
const imgList = [
  'https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/tmpdir/202006161451420001821321.png', // 1 首页
  'https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/tmpdir/202006161455090001821322.png', // 2 方案推广
  'https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/tmpdir/202006161456460001821325.png', // 3 工作台
  'https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/tmpdir/202006161455460001821323.png', // 4 商城
  'https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/tmpdir/202006161456060001821324.png', // 5 我的
]

function Home(props) {
  const [activeKey, setActiveKey] = useState('customer');
  const [pcFormVisible, setPcFormVisible] = useState(true);
  const [appFormVisible, setAppFormVisible] = useState(false);
  const [pcForm] = Form.useForm();
  const [appForm] = Form.useForm();
  const onceRef = useRef(null);

  useEffect(() => {
    const pcSwiper = new Swiper("#banner",{
      loop: true,
      loopedSlides: 5,
      slidesPerView: 'auto',
      autoplay: false,
      centeredSlides: true,
      initialSlide: 2,
      watchSlidesProgress: true,
      slideToClickedSlide: true,
      on: {
        progress: function(progress) {
          for (let i = 0; i < this.slides.length; i++) {
            let slide = this.slides.eq(i);
            let slideProgress = this.slides[i].progress;
            if (Math.abs(slideProgress) > 1) {
              var modify = (Math.abs(slideProgress) - 1) * 0.4 + 1;
            }
            let translate = slideProgress * modify * 120 + 'px';
            let scale = 1 - Math.abs(slideProgress) / 12;
            let zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
            slide.transform('translateX(' + translate + ') scale(' + scale + ')');
            slide.css('zIndex', zIndex);
            slide.css('opacity', 1);
            if (Math.abs(slideProgress) > 3) {
              slide.css('opacity', 0);
            }
          }
        },
        setTransition: function(transition) {
          for (var i = 0; i < this.slides.length; i++) {
            const slide = this.slides.eq(i)
            slide.transition(transition);
          }
        }
      }
    })

    const appSwiper = new Swiper("#app_banner",{
      loop: true,
      loopedSlides: 5,
      slidesPerView: 'auto',
      autoplay: false,
      centeredSlides: true,
      initialSlide: 2,
      watchSlidesProgress: true,
      slideToClickedSlide: true,
      on: {
        progress: function(progress) {
          for (let i = 0; i < this.slides.length; i++) {
            let slide = this.slides.eq(i);
            let slideProgress = this.slides[i].progress;
            if (Math.abs(slideProgress) > 1) {
              var modify = (Math.abs(slideProgress) - 1) * 0.4 + 1;
            }
            let translate = slideProgress * modify * 75 + 'px';
            let scale = 1 - Math.abs(slideProgress) / 12;
            let zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
            slide.transform('translateX(' + translate + ') scale(' + scale + ')');
            slide.css('zIndex', zIndex);
            slide.css('opacity', 1);
            if (Math.abs(slideProgress) > 3) {
              slide.css('opacity', 0);
            }
          }
        },
        setTransition: function(transition) {
          for (var i = 0; i < this.slides.length; i++) {
            const slide = this.slides.eq(i)
            slide.transition(transition);
          }
        }
      }
    })

    const isMobile = /Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent);
    homeStore.addHomePv({ type: isMobile ? 2 : 1 });

    window.onload = () => {
      const bannerDom = document.getElementById('home_banner');
      bannerDom.dataset.src && bannerDom.setAttribute('src', bannerDom.dataset.src)
    }
  }, [onceRef])

  const toLogin = () => {
    window.open('/login');
  }

  const toRegister = () => {
    window.open('/register');
  }

  const addCustomerClue = async (params) => {
    const { leadsSource: type } = params;
    const { success, data } = await homeStore.addCustomerClue(params);
    if (success) {
      Modal.confirm({
        width: type === 2 ? '300px' : '420px',
        content: '您的信息提交成功。',
        cancelButtonProps: {
          style: { display: 'none' }
        },
        okButtonProps: {
          style: { width: '100%' }
        },
        onOk: () => {
          if (type === 1) {
            pcForm.resetFields();
          } else {
            setAppFormVisible(false);
            appForm.resetFields();
          }
        }
      })
    }
  }

  const onPcFormSubmit = () => {
    pcForm.validateFields().then(values => {
      const { pc_mobile, pc_name, pc_company, pc_position } = values;
      const params = {
        contactNumber: pc_mobile,
        leadsSource: 1, // pc-1 h5-2
      }
      pc_name && (params.contactName = pc_name);
      pc_company && (params.company = pc_company);
      pc_position && (params.position = pc_position);
      addCustomerClue(params);
    })
  }

  const onAppFormSubmit = () => {
    appForm.validateFields().then(values => {
      const { app_mobile, app_name, app_company, app_position } = values;
      const params = {
        contactNumber: app_mobile,
        leadsSource: 2, // pc-1 h5-2
      }
      app_name && (params.contactName = app_name);
      app_company && (params.company = app_company);
      app_position && (params.position = app_position);
      addCustomerClue(params);
    })
  }

  return (
    <div className={styles.home_wrap}>
      {/* topbar */}
      <div className={styles.home_top}>
        <div className={styles.home_top_logo} />
        <div className={styles.home_top_right}>
          <div className={styles.home_contact_tel}>联系电话：400-160-1160</div>
          <div className={styles.home_login} onClick={toLogin}>登录</div>
          <div className={styles.home_register} onClick={toRegister}>免费注册</div>
        </div>
      </div>
      <div className={styles.app_home_top}>
        欢迎来到飞象小程序商城工作台！
      </div>

      {/* banner */}
      <img className={styles.home_banner} id="home_banner" data-src="https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/tmpdir/202006190951490001822715.png" src="https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/tmpdir/202006181144430001822353.png" />
      <div className={styles.app_home_banner} />

      {/* 场景介绍 */}
      <div className={styles.home_product_explain_wrap}>
        <div className={styles.home_product_explain} />
      </div>
      <div className={styles.app_home_product_explain} />

      {/* 工作台 */}
      <div className={styles.home_workbench}>
        <div className={styles.home_workbench_header}>企业自己的工作台</div>

        <div className={styles.home_workbench_miniapp_wrap}>
          <div className={styles.home_workbench_miniapp_header} />
          <div className={styles.home_workbench_miniapp_content}>
            <div className="swiper-container" id="banner">
              <div className="swiper-wrapper">
                {imgList.map((item, index) => (
                  <div className="swiper-slide" key={index + ''} style={{ width: 255, height: 553 }}>
                    <img style={{ width: 255, height: 553 }} src={item} alt="轮播图" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.app_home_workbench_miniapp_wrap}>
          <div className={styles.app_home_workbench_miniapp_header}>
            立足于微信小程序
          </div>
          <div className={styles.app_home_workbench_miniapp_content}>
            <div className="swiper-container" id="app_banner">
              <div className="swiper-wrapper">
                {imgList.map((item, index) => (
                  <div className="swiper-slide" key={index + ''} style={{ width: 155, height: 300 }}>
                    <img style={{ width: 155, height: 300 }} src={item} alt="轮播图" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.home_workbench_pc_wrap}>
          <div className={styles.home_workbench_pc_header} />
          <div className={styles.home_workbench_pc_content} />
        </div>

        <div className={styles.app_home_workbench_pc_wrap}>
          <div className={styles.app_home_workbench_pc_header}>
            pc端辅助
          </div>
          <div className={styles.app_home_workbench_pc_content} />
        </div>
      </div>

      {/* 功能介绍 */}
      <div className={styles.home_introduction}>功能介绍</div>
      <div className={styles.home_func_wrap}>
        <div className={styles.home_func}>
          <div className={styles.home_func_tab_wrap}>
            {tabList.map(item => (
              <div
                className={`${styles.home_func_tab_item} ${activeKey === item.key ? styles.home_func_tab_item_active : ''}`}
                onClick={() => setActiveKey(item.key)}
                key={item.key}
              >{item.title}</div>
            ))}
          </div>
          {activeKey === 'customer' && (
            <img className={styles.home_func_tab_content} src="https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/tmpdir/202006181146490001822354.png" />
          )}
          {activeKey === 'sale' && (
            <img className={styles.home_func_tab_content} src="https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/tmpdir/202006181152490001822355.png" />
          )}
          {activeKey === 'market' && (
            <img className={styles.home_func_tab_content} src="https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/tmpdir/202006181153050001822356.png" />
          )}
        </div>
      </div>

      {/* 合作伙伴 */}
      <div className={styles.home_partner_wrap}>
        <div className={styles.home_partner} />
      </div>

      <div className={styles.home_footer}>
        <div className={styles.home_footer_header}>小程序商城工作台</div>
        <div className={styles.home_footer_content}>@2016 - 2019 | 杭州飞象企服网络技术有限公司 版权所有 | 浙B2-20140040 | 浙ICP备13001797号-1</div>
      </div>

      <div className={`${styles.home_pc_form} ${pcFormVisible ? '' : styles.home_pc_form_closed}`}>
        <div className={styles.home_pc_title}>
          <strong>拓客户</strong>&nbsp;&nbsp;<strong>管销售</strong>&nbsp;&nbsp;<strong>做交易</strong>
          <br />
          就用<strong>小程序商城工作台</strong>
        </div>
        <div className={styles.home_pc_sub_title}>留下您的信息，我们跟您联系</div>
        <Form form={pcForm} labelCol={{ span: 6 }}>
          <Form.Item
            label="手机号"
            name="pc_mobile"
            required
            rules={[
              { required: true, message: '请输入手机号码' },
              { pattern: /^1(3|4|5|7|8)\d{9}$/, len: 11, message: '请输入正确的手机号码' }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="姓名" name="pc_name" rules={[{ max: 10, message: '超过长度限制' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="公司" name="pc_company" rules={[{ max: 60, message: '超过长度限制' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="职位" name="pc_position" rules={[{ max: 10, message: '超过长度限制' }]}>
            <Input />
          </Form.Item>
        </Form>
        <Button style={{ width: '100%' }} onClick={onPcFormSubmit}>提交</Button>
        
        <div className={styles.home_pc_form_zoom} onClick={() => setPcFormVisible(!pcFormVisible)}>
          {pcFormVisible ? (
            <RightOutlined />
          ) : (
            <LeftOutlined />
          )}
        </div>
      </div>
      
      <div className={styles.app_home_form_btn} onClick={() => setAppFormVisible(true)}>立刻获取小程序</div>
      <div className={`${styles.app_home_form_wrap} ${appFormVisible ? styles.app_home_form_wrap_show : ''}`}>
        <div className={styles.app_home_form}>
          <div className={styles.app_home_sub_title}>留下您的信息，工作人员会跟您取得联系！</div>
          <Form form={appForm} className="home_app_form">
            <Form.Item
              label={<span>手机号：</span>}
              name="app_mobile"
              required
              rules={[
                { required: true, message: '请输入手机号码' },
                { pattern: /^1(3|4|5|7|8)\d{9}$/, len: 11, message: '请输入正确的手机号码' }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label={<span>您的姓名：</span>} name="app_name" rules={[{ max: 10, message: '超过长度限制' }]}>
              <Input />
            </Form.Item>
            <Form.Item label={<span>公司名称：</span>} name="app_company" rules={[{ max: 60, message: '超过长度限制' }]}>
              <Input />
            </Form.Item>
            <Form.Item label={<span>您的职位：</span>} name="app_position" rules={[{ max: 10, message: '超过长度限制' }]}>
              <Input />
            </Form.Item>
          </Form>
          <div className={styles.app_home_form_bottom_btn_wrap}>
            <Button className={styles.app_home_form_bottom_btn} onClick={() => { setAppFormVisible(false); appForm.resetFields() }}>取消</Button>
            <Button className={styles.app_home_form_bottom_btn} onClick={onAppFormSubmit}>立即提交</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(Home);

