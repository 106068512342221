import React from 'react';
import PropTypes from 'prop-types';
import styles from '../index.module.scss'
import {CloseCircleFilled} from '@ant-design/icons'

function ModuleNav(props) {
  const {list, num, onClick,onCancel} = props;

  const sizeMap = {
    2: 182,
    3: 120,
    4: 88,
  }
  return (
    <div className={styles.module_nav_container} style={{marginBottom: 4}} onClick={onClick}>
      {list.map((item, index) =>
        <div key={index} style={{
          width: sizeMap[num],
          height: sizeMap[num],
          marginRight: (index + 1) % num === 0 ? 0 : 4,
          marginBottom: 4
        }}>
          <img src={item.imageUrl} alt="" style={{
            width: sizeMap[num],
            height: sizeMap[num],}}/>
        </div>
      )}
      <div className={styles.module_cancel} onClick={e=>{
        e.stopPropagation();
        onCancel()
      }}><CloseCircleFilled/></div>
    </div>
  );
}

ModuleNav.propTypes = {
  list: PropTypes.array.isRequired,
  num: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  onCancel:PropTypes.func
};

export default ModuleNav;
