import React from 'react';
import PropTypes from 'prop-types';
import {Carousel} from 'antd'
import {CloseCircleFilled} from "@ant-design/icons"
import styles from "../index.module.scss";

function ModuleSwiper(props) {
  const {list, size,onClick,onCancel} = props

  const sizeHeightMap = {
    small: 180 / 2,
    middle: 230 / 2,
    large: 350 / 2
  }
  return (
    <div className={styles.module_container} onClick={onClick}>
      <Carousel autoplay>
        {list.map((item, index) =>
          <div style={{
            width: 750 / 2,
            height: sizeHeightMap[size]
          }} key={index}>
              <img src={item.imageUrl}
                   alt=""
                   style={{width:"100%",height:sizeHeightMap[size]}}/>
          </div>
        )}
      </Carousel>
      <div className={styles.module_cancel} onClick={e=>{
        e.stopPropagation();
        onCancel()
      }}><CloseCircleFilled/></div>
    </div>
  );
}

ModuleSwiper.propTypes = {
  list: PropTypes.array.isRequired,
  size: PropTypes.oneOf(["small", "middle", "large"]),
  onClick:PropTypes.func,
  onCancel:PropTypes.func
};

export default ModuleSwiper;
