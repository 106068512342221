import React from 'react';
import {Modal} from "antd";
import PropTypes from 'prop-types';

function ImgPreview(props) {
  const {image, visible, onCancel, title} = props
  return (
    <Modal
      visible={visible}
      title={title || image.substring(image.lastIndexOf('/') + 1)}
      footer={null}
      onCancel={onCancel}
    >
      <img alt="example" style={{width: '100%'}} src={image}/>
    </Modal>
  );
}

ImgPreview.propTypes = {
  image: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string
};

export default ImgPreview;
