import React, {Component} from "react"
import {Menu} from 'antd'
import PropTypes from 'prop-types'

import styles from "./index.module.scss"

const {Item} = Menu;

/**
 * 页面上部的menu
 * @props data:Array{
 *   label
 *   key
 *   link
 * }
 * @props value
 * @props onChange:function
 */
class PageMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  change=e=>{
    const {data} = this.props;
    let item=data.find(item=>item.key===e.key);
    window.open(item.link,"_self");
    this.props.onChange && this.props.onChange(e.key);
  }

  render() {
    const {data,value} = this.props;
    return (
      <div className={styles.page_menu_container}>
        <div className={styles.menu_container}>
          <Menu mode="horizontal" selectedKeys={[value]} onClick={this.change}>
            {data.map(item =>
              <Item key={item.key}>{item.label}</Item>
            )}
          </Menu>
        </div>

      </div>
    );
  }

}


/**
 * 页面上部的menu
 * @props data:Array{
 *   label
 *   key
 *   link
 * }
 * @props value
 * @props onChange:function
 */
PageMenu.propTypes={
  data:PropTypes.arrayOf(PropTypes.shape({
    label:PropTypes.string,
    key:PropTypes.any,
    link:PropTypes.string
  })),
  value:PropTypes.any,
  onChange:PropTypes.func
}


export default PageMenu;
