import React,{useState} from 'react';
import {Modal} from "antd";
import PropTypes from 'prop-types';

function PreviewPDF(props) {
  const {visible, onCancel, src,title} = props
const [numPages,setNumPages]=useState(null)
  const [pageNumber,setPageNumber]=useState(1)
  return (
    <Modal visible={visible} onCancel={onCancel} footer={false} width={748} title={title}>
      <iframe src={src} style={{height: 600, width: 700}}></iframe>
    </Modal>
  );
}

PreviewPDF.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  src: PropTypes.string,
  title: PropTypes.string,
};

export default PreviewPDF;
