import React, {Component} from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss'
import {formatTime} from "utils/tools";

function ActivityDetail(props) {
  const {item} = props
  return (
    <div className={styles.content_container}>
      <p className={styles.detail_title}>{item.name}</p>
      <img src={item.coverImgUrl} className={styles.detail_cover}/>
      <p className={styles.detail_content} dangerouslySetInnerHTML={{__html:item.content}}/>
      <p className={styles.detail_time}>活动时间：<span>{`${formatTime(item.startTime)}--${formatTime(item.endTime)}`}</span></p>
      <p className={styles.detail_tip}>活动期内报名才能生效哦，尽快填写报名信息吧</p>
      <img src="https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/202006061200010003024618.png" alt="" style={{width:"100%"}}/>
    </div>
  );
}

ActivityDetail.propTypes = {
  item: PropTypes.object.isRequired
};

export default ActivityDetail;
