import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Steps, Form, Button, Input, message } from 'antd';

import {Layout} from "components";
import commonStore from 'stores/common';
import './register.scss';

const md5 = require('md5');
const { TopBar } = Layout;
const { Step } = Steps;

function ResetPasswrod(props) {
  const [currentStep, setCurrentStep] = useState(0);
  const [accountData, setAccountData] = useState({});
  const [telStr, setTelStr] = useState('');
  const [codeState, setCodeState] = useState(0); // 0-获取验证码 1-倒计时 2-重新获取
  const [second, setSecond] = useState(60);
  const [isCodeLoading, setIsCodeLoading] = useState(false);
  const [codeInterval, setCodeInterval] = useState(null);
  const [resetForm] = Form.useForm();

  const customDot = (dot, { status, index }) => (
    <span>{index + 1}</span>
  )

  const codeBtnDom = () => {
		return codeState === 0 ? '获取验证码' : codeState === 1 ? `${second}s` : '重新获取'
	}

  const tick = () => {
    setSecond((prevSecond) => {
      if (prevSecond === 0) {
        clearInterval(codeInterval);
        setCodeInterval(null);
        setCodeState(2);
        return 60;
      }
      return prevSecond - 1;
    })
	}

  const getValidateCode = () => {
    if (codeState === 1) return false;

    if (accountData.telephone) {
      setIsCodeLoading(true);

      commonStore.getSmsCode({
        mobile: accountData.telephone,
      }).then((res) => {
        if (res.code === '2000') {
          setCodeState(1);
          setSecond(60);
          setIsCodeLoading(false);
          clearInterval(codeInterval);
          setCodeInterval(setInterval(tick, 1000));
        } else {
          setIsCodeLoading(false);
        }
      }).catch((err) => {
        setIsCodeLoading(false);
      })
    }
	}

  
  const onFirstStepSubmit = () => {
    resetForm.validateFields().then(async (values) => {
      const { reset_account } = values;
      const { success, data } = await commonStore.checkResetAccount({ userName: reset_account });
      if (success) {
        setAccountData(data);
        setTelStr(data.telephone.toString().replace(data.telephone.toString().substring(3, 7), "****"));
        setCurrentStep(1);
      }
    })
  }
  
  const resetPasswrod = () => {
    resetForm.validateFields().then(async (values) => {
      const { reset_smsCode, reset_loginPwd, reset_confirmPwd } = values;
      const params = {
        customerType: 20,
        username: accountData.username,
        telephone: accountData.telephone,
        smsCode: reset_smsCode,
        pwd: md5(reset_loginPwd),
        confirmPwd: md5(reset_confirmPwd)
      }

      const { success } = await commonStore.resetPassword(params);
      if (success) {
        message.success('密码重置成功！');
        setTimeout(() => {
          window.location.href = '/login';
        }, 500)
      }
    })
  }
  
  const goLogin = () => {
    props.history.push('/login');
  }

  return (
    <div className="register-wrap">
      <TopBar type="login" page="resetPassword"/>

      <div className="register-box">
        <Steps current={currentStep} labelPlacement="vertical" progressDot={customDot}>
          <Step title="输入账号" />
          <Step title="安全验证，设置新密码" />
          <Step title="完成" />
        </Steps>

        <Form form={resetForm} className="register-form">
          {currentStep === 0 && (
            <>
              <Form.Item
                name="reset_account"
                rules={[
                  { required: true, message: '账户名不可为空' },
                  { min: 4, max: 20, message: '长度只能在4-20个字符之间' },
                  { pattern: /^[\u4e00-\u9fa5a-zA-Z0-9]+$/, message: '仅支持中英文及数字' },
                ]}
                validateTrigger="onBlur"
              >
                <Input className="register-form-input" minLength={4} maxLength={20} prefix="账&nbsp;&nbsp;户&nbsp;&nbsp;名" placeholder="请输入你需要找回的账户名" />
              </Form.Item>
              <Button type="danger" className="form-ok-btn" onClick={onFirstStepSubmit}>提 交</Button>
            </>
          )}

          {currentStep === 1 && (
            <>
              <Form.Item >
                <p style={{ fontSize: 18 }}>您正在找回账号 <span style={{ color: '#DB211D' }}>{accountData.username}</span> 的密码，请验证安全手机号</p>
                <p style={{ fontSize: 16, margin: '10px 0 34px 0' }}>安全手机号: <span style={{ color: '#333', fontSize: 20 }}>{telStr}</span> </p>
              </Form.Item>
              <Form.Item>
                <Form.Item
                  name="reset_smsCode"
                  validateTrigger="onBlur"
                  rules={[{ required: true, message: '验证码不可为空' }]}
                  noStyle
                >
                  <Input className="register-form-input code-input" maxLength={10} prefix="验&nbsp;&nbsp;证&nbsp;&nbsp;码" placeholder="请输入验证码" />
                </Form.Item>
                <Button type="danger" disabled={codeState === 1} className="code-btn" onClick={getValidateCode} loading={isCodeLoading} >
                  {codeBtnDom()}
                </Button>
              </Form.Item>
              <Form.Item
                name="reset_loginPwd"
                getValueFromEvent={event => event.target.value.replace(' ', '').replace(/[\u4e00-\u9fa5]/g, '')}
                validateTrigger="onBlur"
                rules={[
                  { required: true, message: '登录密码不可为空' },
                  { min: 8, max: 20, message: '长度只能在8-20个字符之间' },
                  { pattern: /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{8,20}$/, message: '字母、数字、字符其中至少两种' },
                ]}
              >
                <Input className="register-form-input" prefix="设&nbsp;置&nbsp;密&nbsp;码" type="password" placeholder="请设置登录密码" />
              </Form.Item>
              <Form.Item
                name="reset_confirmPwd"
                validateTrigger="onBlur"
                validateFirst={true}
                getValueFromEvent={event => event.target.value.replace(' ', '').replace(/[\u4e00-\u9fa5]/g, '')}
                rules={[
                  { required: true, message: '登录密码不可为空' },
                  { min: 8, max: 20, message: '长度只能在8-20个字符之间' },
                  { pattern: /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{8,20}$/, message: '字母、数字、字符其中至少两种' },
                  {
                    validator: (rule, value, callback) => {
                      if (value !== resetForm.getFieldValue('reset_loginPwd')) {
                        callback('两次输入密码不一致')
                      } else {
                        callback()
                      }
                    }
                  },
                ]}
              >
                <Input className="register-form-input" prefix="确&nbsp;认&nbsp;密&nbsp;码" type="password" placeholder="请再次输入登录密码" />
              </Form.Item>
              
              <Button className="form-ok-btn" type="danger" onClick={resetPasswrod}>完 成</Button>
            </>
          )}
        </Form>
      </div>
    </div>
  )
}

export default observer(ResetPasswrod);
