import { action, observable } from 'mobx';
import httpClient from '../../utils/httpClient';
import api from '../../config/api';

const { customer, employee, common } = api;

class CustomerStore {
  @observable waitAuditCount = 0; // 待审核的数量
  @observable comCustomerList = []; // 公司客户列表
  @observable comCustomerPagination = {}; // 公司客户列表分页信息
  @observable comContactsList = []; // 公司联系人列表
  @observable comContactsPagination = {}; // 公司联系人分页信息
  @observable comPotentialCustomerList = []; // 公司潜在客户
  @observable comPotentialCustomerPagination = {}; // 公司潜在客户列表分页信息
  @observable customerAuditList = []; // 客户等级调整审核列表
  @observable customerAuditPagination = {}; // 客户等级调整审核列表分页信息
  @observable myCustomerList = []; // 我的客户列表
  @observable myCustomerPagination = {}; // 我的客户列表分页信息
  @observable myContactsList = []; // 我的联系人列表
  @observable myContactsPagination = {}; // 我的联系人分页信息
  @observable myPotentialCustomerList = []; // 我的潜在客户
  @observable myPotentialCustomerPagination = {}; // 我的潜在客户列表分页信息

  /**
   * 获取省市信息
   * @param {*} params
   */
  @action
  async getAreas(params) {
    const res = await httpClient.post(common.GET_AREAS, { ...params });
    return res;
  }

  /**
   * 获取公司客户列表
   * @param {*} params
   */
  @action
  async getCompanyCustomerList(params) {
    const res = await httpClient.get(customer.GET_COMPANY_CUSTOMER, { params });
    const { success, data } = res;
    if (success) {
      this.comCustomerList = data.list.map(item => ({ key: item.buyerId, ...item}));
      this.comCustomerPagination = {
        current: data.page,
        pageSize: data.pageSize,
        total: data.total,
      }
    }
    return res;
  }

  /**
   * 变更归属
   * @param {*} params
   */
  @action
  async changeCustomerOwner(params) {
    const res = await httpClient.put(customer.PUT_CHANGE_CUSTOMER_OWNER, { ...params });
    return res;
  }

  /**
   * 添加公司客户
   * @param {*} params
   */
  @action
  async addCompanyCustomer(params) {
    const res = await httpClient.post(customer.POST_ADD_COMPANY_CUSTOMER, { ...params });
    return res;
  }

  /**
   * 编辑公司客户
   * @param {*} params
   */
  @action
  async updateCompanyCustomer(params) {
    const res = await httpClient.put(customer.PUT_UPDATE_COMPANY_CUSTOMER, { ...params });
    return res;
  }

  /**
   * 添加客户
   * @param {*} params
   */
  @action
  async addCustomer(params) {
    const res = await httpClient.post(customer.POST_ADD_CUSTOMER, { ...params });
    return res;
  }

  /**
   * 编辑客户
   * @param {*} params
   */
  @action
  async updateCustomer(params) {
    const res = await httpClient.put(customer.PUT_UPDATE_CUSTOMER, { ...params });
    return res;
  }

  /**
   * 获取公司员工列表
   * @param {*} params
   */
  @action
  async getCompanyEmployeeList(params) {
    const res = await httpClient.get(`${employee.GET_EMPLOYEE_BY_COMPANY}/${params.companyId}`);
    return res;
  }

  /**
   * 获取公司联系人列表
   * @param {*} params
   */
  @action
  async getCompanyContactsList(params) {
    const res = await httpClient.get(customer.GET_COMPANY_CONTACTS_LIST, { params });
    const { success, data } = res;
    if (success) {
      this.comContactsList = data.list.map(item => ({ key: item.id, ...item }));
      this.comContactsPagination = {
        current: data.page,
        pageSize: data.pageSize,
        total: data.total,
      }
    }
    return res;
  }

  /**
   * 获取客户名称列表
   * @param {*} params
   */
  @action
  async getCustomerNameList(params) {
    const res = await httpClient.get(customer.GET_CUSTOMER_NAME_LIST, { params });
    return res;
  }

  /**
   * 获取公司潜在客户列表
   * @param {*} params
   */
  @action
  async getCompanyPotentialCustomerList(params) {
    const res = await httpClient.get(customer.GET_COMPANY_POTENTIAL_CUSTOMER_LIST, { params });
    const { success, data } = res;
    if (success) {
      this.comPotentialCustomerList = data.list.map(item => ({ key: item.refId, ...item}));
      this.comPotentialCustomerPagination = {
        current: data.page,
        pageSize: data.pageSize,
        total: data.total,
      }
    }
    return res;
  }

   /**
   * 变更潜在客户归属
   * @param {*} params
   */
  @action
  async changePotentialCustomerOwner(params) {
    const res = await httpClient.post(customer.POST_CHANGE_POTENTIAL_CUSTOMER_OWNER, { ...params });
    return res;
  }

  /**
   * 获取客户等级调整审批总数
   * @param {*} params
   */
  @action
  async getAuditCount(params) {
    const res = await httpClient.get(`${customer.GET_AUDIT_COUNT}/${params.companyId}`);
    const { success, data } = res;
    if (success) {
      this.waitAuditCount = data;
    }
    return res;
  }

  /**
   * 获取客户等级审核列表
   * @param {*} params 
   */
  @action
  async getCustomerAuditList(params) {
    const res = await httpClient.get(customer.GET_CUSTOMER_AUDIT_LIST, { params });
    const { success, data } = res;
    if (success) {
      this.customerAuditList = data.list.map(item => ({ key: item.id, ...item }));
      this.customerAuditPagination = {
        current: data.page,
        pageSize: data.pageSize,
        total: data.total,
      }
    }
    return res;
  }

   /**
   * 通过、驳回客户等级调整
   * @param {*} params
   */
  @action
  async customerLevelAuditOps(params) {
    const res = await httpClient.put(customer.PUT_AUDIT_OPS, { ...params });
    return res;
  }

   /**
   * 获取客户等级列表
   * @param {*} params
   */
  @action
  async getCustomerLevelList(params) {
    const res = await httpClient.get(customer.GET_CUSTOMER_LEVEL_LIST, { params });
    return res;
  }

   /**
   * 添加客户等级
   * @param {*} params
   */
  @action
  async addCustomerLevel(params) {
    const res = await httpClient.post(customer.POST_ADD_CUSTOMER_LEVEL, { ...params });
    return res;
  }

   /**
   * 编辑客户等级
   * @param {*} params
   */
  @action
  async updateCustomerLevel(params) {
    const res = await httpClient.put(customer.PUT_UPDATE_CUSTOMER_LEVEL, { ...params });
    return res;
  }

  /**
   * 获取客户标签列表
   * @param {*} params
   */
  @action
  async getCustomerTagList(params) {
    const res = await httpClient.get(customer.GET_CUSTOMER_TAG_LIST, { params });
    return res;
  }

  /**
   * 添加客户标签
   * @param {*} params
   */
  @action
  async addCustomerTag(params) {
    const res = await httpClient.post(customer.POST_ADD_CUSTOMER_TAG, { ...params });
    return res;
  }

   /**
   * 编辑客户标签
   * @param {*} params
   */
  @action
  async updateCustomerTag(params) {
    const res = await httpClient.put(customer.PUT_UPDATE_CUSTOMER_TAG, { ...params });
    return res;
  }

  /**
   * 删除客户标签
   * @param {*} params
   */
  @action
  async delCustomerTag(params) {
    const res = await httpClient.delete(`${customer.DELETE_CUSTOMER_TAG}/${params.id}`, {});
    return res;
  }

  /**
   * 获取我的客户列表
   * @param {*} params
   */
  @action
  async getMyCustomerList(params) {
    const res = await httpClient.get(customer.GET_CUSTOMER_BY_COMPANY, { params });
    const { success, data } = res;
    if (success) {
      this.myCustomerList = data.list.map(item => ({ key: item.buyerId, ...item}));
      this.myCustomerPagination = {
        current: data.page,
        pageSize: data.pageSize,
        total: data.total,
      }
    }
    return res;
  }

  /**
   * 获取我的联系人列表
   * @param {*} params
   */
  @action
  async getMyContactsList(params) {
    const res = await httpClient.get(customer.GET_MY_CONTACTS_LIST, { params });
    const { success, data } = res;
    if (success) {
      this.myContactsList = data.list.map(item => ({ key: item.id, ...item }));
      this.myContactsPagination = {
        current: data.page,
        pageSize: data.pageSize,
        total: data.total,
      }
    }
    return res;
  }

  /**
   * 添加我的联系人
   * @param {*} params
   */
  @action
  async addMyContacts(params) {
    const res = await httpClient.post(customer.POST_ADD_CONTACTS, { ...params });
    return res;
  }

   /**
   * 编辑我的联系人
   * @param {*} params
   */
  @action
  async updateMyContacts(params) {
    const res = await httpClient.put(customer.PUT_UPDATE_CONTACTS, { ...params });
    return res;
  }

  /**
   * 上传电子名片
   * @param {*} params
   */
  @action
  async uploadBusinessCard(params) {
    const res = await httpClient.post(common.POST_UPLOAD_FILE, params);
    return res;
  }

  /**
   * 获取我的潜在客户列表
   * @param {*} params
   */
  @action
  async getMyPotentialCustomerList(params) {
    const res = await httpClient.get(customer.GET_POTENTIAL_CUSTOMER_LIST, { params });
    const { success, data } = res;
    if (success) {
      this.myPotentialCustomerList = data.list.map(item => ({ key: item.refId, ...item}));
      this.myPotentialCustomerPagination = {
        current: data.page,
        pageSize: data.pageSize,
        total: data.total,
      }
    }
    return res;
  }

  /**
   * 我的潜在客户添加为联系人
   * @param {*} params
   */
  @action
  async addAsContacts(params) {
    const res = await httpClient.post(customer.POST_ADD_AS_CONTACTS, { ...params });
    return res;
  }
}

export default new CustomerStore();
