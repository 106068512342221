import React, {useState} from 'react';
import PropTypes from 'prop-types'
import {Modal, Input, Row, Col} from "antd";

import {config} from "config";
import {judgeNum} from "utils/validate";

import styles from './index.module.scss'

const {theme} = config

function AddGoodsChangePrice(props) {
  const [salePrice, setSalePrice] = useState();
  const [marketPrice, setMarketPrice] = useState();

  return (
    <Modal visible={props.visible}
           title="所有规格使用同一价格"
           centered
           onCancel={props.onCancel}
           onOk={() => props.onOk(props.type, [salePrice, marketPrice])}>

      <div className={styles.body_container}>
        <Row gutter={16}
             style={{marginBottom: theme.distances["basic-gap"] * 2}}>
          <Col span={6} style={{
            textAlign: "right"
          }}>
            <span className={styles.content_title_required}>销售价：</span>
          </Col>
          <Col span={18}>
          <span>
            <Input maxLength={10}
                   value={salePrice}
                   allowClear
                   placeholder="请输入销售价"
                   onChange={e => setSalePrice(judgeNum(e.target.value.trim(),"money"))}
                   style={{
                     width: 160
                   }}/></span>
          </Col>
        </Row>
        {props.type==="collect" && <Row gutter={16}>
          <Col span={6} style={{
            textAlign: "right"
          }}>
            <span className={styles.content_title_required}>市场价：</span>
          </Col>
          <Col span={18}>
          <span>
            <Input maxLength={10}
                   value={marketPrice}
                   allowClear
                   placeholder="请输入市场价"
                   onChange={e => setMarketPrice(judgeNum(e.target.value.trim(),"money"))}
                   style={{
                     width: 160
                   }}/></span>
          </Col>
        </Row>}
      </div>
    </Modal>
  )
}

AddGoodsChangePrice.propTypes = {
  visible: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(["collect", "substitute"]),
  onOk: PropTypes.func,
  onCancel: PropTypes.func
}

export default AddGoodsChangePrice;
