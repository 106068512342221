const colors = {
  "theme": "#ff440a",
  "auxiliary-orange": "#ff812d",
  "auxiliary-blue": "#2a8ff7",
  "success": "#5bc531",
  "warn": "#f5ad42",
  "error": "#ed494c",
  "white": "#fff",
  "text-dark": "#4a4a4a",
  "text-grey": "#666",
  "text-light": "#9b9b9b",
  "bg-dark": "#444",
  "bg-grey": "#666",
  "bg-light": "#f7f8fa",
  "line": "#dedede",
  "divider": "#ededef"
}

const sizes = {
  "line": "1px",
  "line-bold": "2px",
  "text-24": "24px",
  "text-20": "20px",
  "text-16": "16px",
  "text-14": "14px",
  "text-12": "12px",
}

const distances = {
  "basic-gap": 8
}

const styles = {
  "solid-border": `${sizes.line} solid ${colors.line}`,
  "dashed-border": `${sizes.line} dashed ${colors.line}`
}

export default {
  colors, sizes, distances, styles
}
