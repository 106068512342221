import React from 'react';
import {Modal} from "antd";
import PropTypes from 'prop-types';
import ActivityDetail from '../../ActivityDetail'

function ActivityDetailPreview(props) {
  const {visible, item, onCancel} = props
  return (
    <Modal title="活动页面预览"
           visible={visible}
           footer={false}
           onCancel={onCancel}
           width={450}
           centered
           bodyStyle={{maxHeight: "667px", overflowY: "auto"}}>
      <ActivityDetail item={item}/>
    </Modal>
  );
}

ActivityDetailPreview.propTypes = {
  visible: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  onCancel: PropTypes.func
};

export default ActivityDetailPreview;
