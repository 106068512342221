import React, {useState, useEffect} from 'react';
import {Modal, Table, Statistic, message, Button, Space} from "antd";
import PropTypes from 'prop-types';
import styles from "./index.module.scss";
import {formatTime} from "utils/tools";
import stores from "stores"


const {appletStore} = stores


function MarketOperateActivityData(props) {
  const {visible, item, onCancel} = props
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [dataList, setDataList] = useState({list: [], total: 0});
  /**
   * 请求列表调用时机
   */
  useEffect(() => {
    _getData();
  }, [pageNo, pageSize])
  /**
   * 页码改变
   * @param page
   * @param pageSize
   * @returns {Promise<void>}
   */
  const changePage = (page, pageSize) => {
    setPageNo(page);
  }

  /**
   * pageSize改变
   * @param current
   * @param size
   * @returns {Promise<void>}
   */
  const changePageSize = (current, size) => {
    setPageNo(1);
    setPageSize(size);
  }
  const activityMap = {
    1: "随机抽奖"
  }

  /**
   * 表格columns
   * @param type
   * @returns {*[]}
   */
  const columns = [
    {
      title: "姓名",
      dataIndex: "name",
      align: "center",
      width: '10%',
    }, {
      title: "手机",
      dataIndex: "phone",
      align: "center",
      width: '15%',
    }, {
      title: "公司",
      dataIndex: "companyName",
      align: "center",
      width: '15%',
    }, {
      title: "职位",
      dataIndex: "position",
      align: "center",
      width: '10%',
    }, {
      title: "地址",
      dataIndex: "address",
      align: "center",
      width: '30%',
    }, {
      title: "报名时间",
      dataIndex: "createTime",
      align: "center",
      render: (text) =>formatTime(text),
      width: '20%',
    }
  ]
  /**
   * 分页参数
   * @type {{total: number, current: number, pageSizeOptions: string[], defaultPageSize: number, onChange: changePage, showTotal: (function(*): string), onShowSizeChange: changePageSize, showQuickJumper: boolean, showSizeChanger: boolean}}
   */
  const pagination = {
    total: dataList.total,
    current: pageNo,
    showSizeChanger: true,
    showQuickJumper: true,
    pageSizeOptions: ["20", "50", "100"],
    defaultPageSize: 20,
    onChange: changePage,
    onShowSizeChange: changePageSize,
    showTotal: total => `共 ${total} 条数据`,
  }

  /**
   * 获取数据
   * @private
   */
  const _getData = async () => {
    const params = {
      pageNo, pageSize, activityId: item.id
    }

    let data = await appletStore.getMarketingActivityDataList(params)
    setDataList(data)
  }

  const exportExcel = async (id) => {
    const { success, data } = await appletStore.getDownloadActivityData({ activityId: id });
    success && window.open(data);
  }

  return (
    <Modal title='活动数据' visible={visible} onCancel={onCancel} footer={false} width={900}
           bodyStyle={{maxHeight: "700px"}}>
      <div className={styles.statistic_container}>
        <span className={styles.item_title}>报名用户数：</span><Statistic value={dataList.total}/>
        <span className={styles.item_title} style={{marginLeft: 20}}>浏览次数：</span><Statistic value={item.visitCount}/>
      </div>

      <Space>
        <span>报名详情</span>
        <Button type="primary" style={{marginLeft: 60}}
                onClick={() => exportExcel(item.id)}>导出Excel</Button>
      </Space>

      <Table dataSource={dataList.list}
             pagination={pagination}
             columns={columns}
             scroll={{ y: 400 }}
             rowKey={record => record.key} style={{marginTop: 20}}/>
    </Modal>
  );
}

MarketOperateActivityData.propTypes = {
  visible: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  onCancel: PropTypes.func
}
export default MarketOperateActivityData;
