import React, { useEffect } from "react";
import zhCN from "antd/lib/locale-provider/zh_CN";
import { ConfigProvider } from "antd";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Loadable from "react-loadable";
import { Provider } from "mobx-react";

import "./App.scss";
// stores
import stores from "stores"

// components
import {Loading} from "components";
// views
import Login from "views/Login";
import Register from 'views/Login/register';
import ResetPassword from 'views/Login/resetPassword';
import Home from 'views/Home'
import Workbench from 'views/Workbench'


function App() {
  const token = localStorage.getItem('token');

  // 登录成功后，获取用户的权限
  useEffect(() => {
    if (token && !stores.commonStore.userRights.isLoaded) {
      stores.commonStore.globalLoading = true;
      stores.commonStore.getUserRights();
    }
  }, [token])

  return (
    <Provider {...stores}>
      <ConfigProvider locale={zhCN}>
        <Router>
          <Switch className="c-body-wrap">

            <Route path="/login" exact component={Login} />
            <Route path="/register" exact component={Register} />
            <Route path="/resetPassword" exact component={ResetPassword} />
            <Route path="/home" exact component={Home} />
            <Route path="/workbench" exact component={Workbench} />


            <Route
              path="/"
              component={Loadable({
                loader: () => import("./layout/Main"),
                loading: Loading
              })}
            />
          </Switch>
        </Router>
      </ConfigProvider>
    </Provider>
  );
}

export default App;
