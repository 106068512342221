import { action, observable } from 'mobx';
import httpClient from '../../utils/httpClient';
import api from '../../config/api';

const { role } = api;


class RoleStore {
  @observable roleList = [];
  @observable rolePagination = {};

  /**
   * 获取角色列表
   * @param {*} params 
   */
  @action
  async getRoleList(params) {
    const res = await httpClient.post(role.GET_ROLE_LIST, { ...params });
    const { success, data } = res;
    if (success) {
      this.roleList = data.list.map(item => ({ key: item.id, ...item }));
      this.rolePagination = {
        current: data.page,
        pageSize: data.pageSize,
        total: data.total,
      }
    }
    return res;
  }
  
  /**
   * 获取权限列表
   * @param {*} params
   */
  @action
  async getPermissionList(params) {
    const res = await httpClient.post(role.GET_PERMISSION_LIST, { ...params });
    return res;
  }

  /**
   * 添加/编辑角色
   * @param {*} params
   * @param {*} type 操作类型ADD/UPDATE
   */
  @action
  async createOrEditRole(params, type) {
    const res = await httpClient.post(role[`POST_${type}_ROLE`], { ...params });
    return res;
  }

   /**
   * 角色授权
   * @param {*} params
   */
  @action
  async roleAuth(params) {
    const res = await httpClient.post(role.POST_ROLE_AUTH, { ...params });
    return res;
  }
}

export default new RoleStore();
