import React, {useState} from 'react';
import {Modal, Upload, Button, message} from 'antd'
import PropTypes from 'prop-types';

import stores from "stores"

import styles from './index.module.scss'

const {goodsStore} = stores

function BatchUploadGoods(props) {
  const {visible, onCancel} = props
  const modelFile="https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/tmpdir/202006171646070001821847.xlsx"
  const [modalType, setModalType] = useState("init")// init success fail
  const [btnLoading, setBtnLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [isRemove, setIsRemove] = useState(false)
  const [failFile, setFailFile] = useState("")
  const changeFile = ({file}) => {
    if (isRemove) {
      setFileList([])

    } else {
      if (!/.\.(xlsx?|csv)$/.test(file.name.toLowerCase())) {
        message.error("请选择正确的文件格式！");
        setFileList([])
        return;
      }
      setFileList([file])
    }
  }

  const removeFile = (file) => {
    setIsRemove(true);
    setFileList([])
  }
  const uploadTable = async () => {
    if (!fileList.length) {
      message.error("请选择上传文件！")
      return;
    }
    let data = new FormData();
    data.append("file", fileList[0]);
    setBtnLoading(true);
    let res = await goodsStore.postUploadGoods(data);
    setBtnLoading(false);
    if (res.success) {
      message.success("上传成功！")
      onCancel()
    } else {
      setModalType("fail");
      setFailFile(res.respFile)
    }

  }

  return (
    <Modal footer={false}
           title={false}
           closable={false}
           visible={visible}
           onCancel={onCancel} centered title="批量导入商品" bodyStyle={{padding: 0}}>
      {modalType === "init" &&
      <div className={styles.modal_body}>
        <div className={styles.upload_container}>
          <Upload fileList={fileList} beforeUpload={() => false} onChange={changeFile} onRemove={removeFile}>
            {!fileList.length &&
            <div className={styles.upload_btn_container}>
              <Button type="primary" style={{width: 100}}>上传文件</Button>
              <p className={styles.upload_tip}>单次上传不超过5000条数据</p>
            </div>}
          </Upload>
        </div>
        <div className={styles.download_btn}>
          <Button type="link" style={{width: 100}}  onClick={()=>window.open(modelFile,"_blank")}>下载表格模板</Button>
        </div>
      </div>}
      {modalType === "init" &&
      <div className={styles.modal_footer}>
        <Button onClick={onCancel} style={{marginRight: 20, width: 100}}>取消</Button>
        <Button type="primary" onClick={uploadTable} style={{marginLeft: 20, width: 100}} loading={btnLoading}>上传</Button>
      </div>}
      {modalType === "fail" &&
      <div className={styles.modal_body}>
        <div className={styles.upload_container}>
          <span>导入的文档有误，查看详情。</span>
        </div>
        <div className={styles.download_btn}>
          <Button type="link" style={{width: 100}} onClick={()=>window.open(failFile,"_blank")}>下载表格</Button>
        </div>
      </div>}
      {modalType === "fail" &&
      <div className={styles.modal_footer}>
        <Button type="primary" onClick={onCancel} style={{width: 100}}>确定</Button>
      </div>}
    </Modal>
  );
}

BatchUploadGoods.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default BatchUploadGoods;
