import React, {Component} from 'react';
import {Divider, Button} from "antd";
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";

import {Layout,Floating,GuideCover} from "components";
import {routers} from "config/route";
import { getMenukeys } from 'utils/tools';

import styles from './index.module.scss'

const {TopBar} = Layout

@inject(...["localStore", "commonStore"])
@withRouter
@observer
class Workbench extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statData: {}, // 统计数据
      waitAuditCount: 0,
      appAuthStatus: 0,
    }
  }

  async componentDidMount() {
    const {getFirstLoginStatus} = this.props.commonStore;
    const {changeTopBarMenu,setGuideShow} = this.props.localStore;
    let item = routers.find(item => +item.id === 1)
    changeTopBarMenu(item)
    this.getMemberStatistics();
    let res = await getFirstLoginStatus({service: "bestgift"});
    console.log(res)
    if (+res === 0) {
      setGuideShow(true)
    }
  }

  // 获取统计数据
  getMemberStatistics = async () => {
    const { success, data } = await this.props.commonStore.getMemberStatistics();
    if (success) {
      this.setState({
        statData: data,
      });
    }
  }

  jumpPage = (page, type) => {
    const {changeTopBarMenu} = this.props.localStore;
    const indexArr = (page + '').split("");

    let item = routers[indexArr[0] - 1];
    console.log([indexArr[0] - 1],[indexArr[1] - 1],[indexArr[2] - 1])
    let r = routers[indexArr[0] - 1].children[indexArr[1] - 1].children[indexArr[2] - 1].route;
    changeTopBarMenu(item)

    this.props.history.push({
      pathname: r,
      query:{
        page:type
      }
    });

  }

  // 获取每个模块底部按钮
  getModuleBtns = (leftnavKeys, type) => {
    let btns = [];

    switch (type) {
      case 'resource': {
        if (leftnavKeys.includes('goodsManage')) {
          btns.push(<div key="goods" className={styles.foot_btn}>商品库管理</div>);
        }
        if (leftnavKeys.includes('materialManage')) {
          btns.push(<div key="material" className={styles.foot_btn}>素材库管理</div>);
        }
        if (leftnavKeys.includes('programmePublic')) {
          btns.push(<div key="programme" className={styles.foot_btn}>公共方案</div>);
        }
        break;
      }
      case 'app-workbench': {
        if (leftnavKeys.includes('app-authorize')) {
          btns.push(<div key="app-authorize" className={styles.foot_btn}>小程序配置</div>);
        }
        if (leftnavKeys.includes('marketing')) {
          btns.push(<div key="marketing" className={styles.foot_btn}>营销活动</div>);
        }
        if (leftnavKeys.includes('message')) {
          btns.push(<div key="message" className={styles.foot_btn}>消息推送</div>);
        }
        break;
      }
      case 'customer': {
        btns.push(<div key="customerManage" className={styles.foot_btn}>客户管理</div>);
        if (leftnavKeys.includes('audit')) {
          btns.push(<div key="audit" className={styles.foot_btn}>等级审批</div>)
        }
        break;
      }
      default: break;
    }

    return btns.map((ele, index, arr) => {
      if (index === arr - 1) {
        return ele;
      }
      return (
        <React.Fragment key={index}>
          {ele}
          <Divider type="vertical" className={styles.foot_divider}/>
        </React.Fragment>
      )
    })
  }

  render() {
    const { statData } = this.state;
    const {setGuideShow,guideShow}=this.props.localStore;
    const { goodsStatistics = {}, customerCount = {}, smallAppInfo = {} } = statData;
    const moduleKeys = getMenukeys('workbench', this.props.commonStore.userRights);
    const leftnavKeys = getMenukeys('leftnav', this.props.commonStore.userRights);

    return (
      <div className={styles.workbench_page}>
        <TopBar type="workbench" menuKeys={getMenukeys('topbar', this.props.commonStore.userRights)}/>
        <div className={styles.content}>
          <div className={styles.module_container}>
            {/* 资源库 */}
            {moduleKeys.includes('resource') && (
              <div className={styles.module_item} onClick={() => { this.props.history.push('/resource') }}>
                <div className={styles.item_title}>
                  <img src="https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/202005151747160003670803.png" className={styles.icon}/>
                  <span className={styles.title}>资源库</span>
                </div>
                <div className={styles.item_body}>
                  <div className={styles.body_item}>
                    <span className={styles.text}>商品总数</span>
                    <span className={styles.value}>{goodsStatistics.goodsTotal || 0}</span>
                  </div>
                  <div className={styles.body_item}>
                    <span className={styles.text}>素材总数</span>
                    <span className={styles.value}>{goodsStatistics.materialTotal || 0}</span>
                  </div>
                  <div className={styles.body_item}>
                    <span className={styles.text}>公共方案</span>
                    <span className={styles.value}>{goodsStatistics.commonSchemeTotal || 0}</span>
                  </div>
                </div>

                <div className={styles.item_foot}>
                  {this.getModuleBtns(leftnavKeys, 'resource')}
                </div>
              </div>
            )}
            {/* 小程序工作台 */}
            {moduleKeys.includes('app-workbench') && (
              <div className={styles.module_item} onClick={() => { this.props.history.push('/app-workbench') }}>
                <div className={styles.item_title}>
                  <img src="https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/202005151747500003670805.png" className={styles.icon}/>
                  <span className={styles.title}>小程序工作台</span></div>
                <div className={styles.item_body}>
                  <div className={styles.body_item}>
                    <img src="https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/202005151748200003670806.png" className={styles.icon}/>
                    <span className={styles.text}>小程序商城运营</span>
                  </div>
                  <div className={styles.body_item}>
                    <img src="https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/202005151748440003670807.png" className={styles.icon}/>
                    <span className={styles.text}>营销活动</span>
                  </div>
                  <div className={styles.body_item}>
                    <img src="https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/202005151749100003670809.png" className={styles.icon}/>
                    <span className={styles.text}>小程序配置</span>
                    {smallAppInfo.authStatus === 0 && (
                      <span className={styles.err_text}>尚未配置</span>
                    )}
                  </div>
                </div>
                <div className={styles.item_foot}>
                  {this.getModuleBtns(leftnavKeys, 'app-workbench')}
                </div>
              </div>
            )}
            {/* 客户 */}
            <div className={styles.module_item} onClick={() => { this.props.history.push('/customer') }}>
              <div className={styles.item_title}>
                <img src="https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/202005151749260003670810.png" className={styles.icon}/>
                <span className={styles.title}>客户</span></div>
              <div className={styles.item_body}>
                <div className={styles.body_item}>
                  <span className={styles.text}>客户数</span>
                  <span className={styles.value}>{customerCount.customerCount || 0}</span>
                </div>
                <div className={styles.body_item}>
                  <span className={styles.text}>潜在客户</span>
                  <span className={styles.value}>{customerCount.potentionCustomerCount || 0}</span>
                </div>
                <div className={styles.body_item}>
                  <span className={styles.text}>等级调整审核</span>
                  <span className={styles.value}>{customerCount.waitAuditCount || 0}</span>
                </div>
              </div>
              <div className={styles.item_foot}>
                {this.getModuleBtns(leftnavKeys, 'customer')}
              </div>
            </div>
          </div>
        </div>

      <Floating/>
        <GuideCover visible={guideShow} onClose={()=>{setGuideShow(false)}}/>
      </div>
    );
  }
}

export default Workbench;
