import React, {useState} from 'react';
import PropTypes from 'prop-types'
import {Modal, Input, Row, Col} from "antd";

import styles from './index.module.scss'

function AddGoodsCustomItems(props) {
  const [name, setName] = useState();
  let title = "";
  switch (props.type) {
    case "spec":
      title = "添加规格";
      break;
    case "attribute":
    case "custom":
      title = "添加自定义";
      break;
  }
  return <Modal visible={props.visible}
                title={title}
                centered
                onCancel={props.onCancel}
                onOk={() => props.onOk(props.type, name)}
                okText="确定添加">

    <div className={styles.body_container}>
      <Row gutter={16}>
        <Col span={4} style={{
          textAlign: "right"
        }}>
          <span className={styles.content_title_required}>名称：</span>
        </Col>
        <Col span={20}>
          <span>
            <Input maxLength={10}
                   value={name}
                   allowClear
                   placeholder="不超过10个字"
                   onChange={e => setName(e.target.value.trim())}
                   style={{
                     width: 160
                   }}/></span>
        </Col>
        <Col span={20} offset={4}>
          <span className={styles.content_tip}>例如：尺寸</span>
        </Col>
      </Row>
    </div>
  </Modal>
}

AddGoodsCustomItems.propTypes = {
  visible: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(["spec", "attribute", "custom"]),
  onOk: PropTypes.func,
  onCancel: PropTypes.func
};

export default AddGoodsCustomItems;
