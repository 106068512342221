import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import {Layout} from "components";
import './index.scss';

const md5 = require('md5');
const { TopBar } = Layout;

@inject("commonStore")
@observer

class Login extends Component {
	state = {
		isLoading: false, // 加载中
	}

  formRef = React.createRef();

	componentDidMount() {
		localStorage.removeItem('token', '')
	}

	//跳转页面
	toPage = (page) => {
		this.props.history.push(page)
	}

	// 登录
	login = () => {
		this.formRef.current.validateFields().then((values) => {
			this.setState({ isLoading: true });
			let psd = md5(values.password);
			this.props.commonStore.postLoginNew({
				authType: 1,
				customerType: 1,
				userName: values.username,
				password: psd,
			}).then(res => {
				this.setState({ isLoading: false })
			}).catch(err => {
				this.setState({ isLoading: false })
			})
		})
	}

	render() {
		const { isLoading } = this.state;

		return (
			<div className='login-wrap'>
        <TopBar type="login" page="login"/>
				<div className="login-box">
					<div className="login-img">
            <img src="https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/tmpdir/202006191131360001822748.png" alt="" style={{ width: '100%', height: '100%' }}/>
          </div>
					<div className='login-form'>
            <div className="login-title">账号登录</div>
						<Form ref={this.formRef}>
							<Form.Item name="username" rules={[{ required: true, message: '账户名不能为空', validateTrigger: 'onBlur' }]}>
                <Input maxLength={31} prefix={<UserOutlined style={{ fontSize: '25px', color: '#999' }} />} size='large' placeholder="请输入账户名" />
							</Form.Item>
							<Form.Item name='password' rules={[{ required: true, message: '密码不能为空' }]}>
								<Input maxLength={30} prefix={<LockOutlined type="lock" style={{ color: '#999', fontSize: '25px' }} />} size='large' type="password" placeholder="请输入密码" />
							</Form.Item>

							<Button type="danger" onClick={this.login} size='large' className="login-form-button" loading={isLoading}>{isLoading ? '登录中...' : '登录'}</Button>

							<p className="txt">
								<Button type="link" className="forget" onClick={() => { this.toPage('/resetPassword') }}>忘记密码</Button>
								<Button type="link" className="register" onClick={() => { this.toPage('/register') }}>免费注册 ></Button>
							</p>
						</Form>


					</div>
				</div>
			</div>
		)
	}
}

export default Login
