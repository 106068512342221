import React, {useState, useEffect} from 'react';
import {Modal, Row, Col, Space, Radio, message} from "antd";
import PropTypes from 'prop-types';

import {Adder} from "components"
import {showMoney} from "utils/tools";

function SettingPurchase(props) {
  const {visible, type, onOk, onCancel, item} = props
  const [timeType, setTimeType] = useState(item.feeRules[0].id);
  const [timeNum, setTimeNum] = useState(1);
  return (
    <Modal visible={visible}
           title={titleMap[type]}
           okText="去支付"
           bodyStyle={{paddingTop: 8, paddingBottom: 8}}
           centered
           onOk={() => onOk({
             timeType,
             timeNum,
             price: timeTypeMap.find(item => item.value === timeType).price,
             total: timeTypeMap.find(item => item.value === timeType).price * timeNum
           })}
           onCancel={onCancel}
    >
      <Row>
        <Col span={24} style={colStyle}>{radioLabelMap[type]}</Col>
        <Col span={24} style={colStyle}>
          <Radio.Group value={timeType} onChange={(e) => setTimeType(e.target.value)}>
            {item.feeRules.map((itemIn, indexIn) =>
              <Radio key={indexIn}
                     value={itemIn.id}>{`按${itemIn.period}，¥${showMoney(itemIn.price)}/${itemIn.period}`}</Radio>)}
          </Radio.Group>
        </Col>
        <Col span={24} style={colStyle}>
          <Space>
            <Adder style={{width: 120}}
                   value={timeNum}
                   onChange={val => setTimeNum(val)}/>
            <span>{`${item.feeRules.find(itemIn => itemIn.id === timeType).period}(${item.feeRules.find(itemIn => itemIn.id === timeType).totalDays}天)`}</span>
          </Space>
        </Col>
        <Col span={24} style={colStyle}>
          <span>费用总计：¥{showMoney(item.feeRules.find(itemIn => itemIn.id === timeType).price * timeNum)}</span>
        </Col>
      </Row>
    </Modal>
  );

}


const titleMap = {
  buy: "产品购买",
  renew: "续费"
}
const radioLabelMap = {
  buy: "请选择您要购买的时长",
  renew: "请选择您要续费的时长"
}
const timeTypeMap = [
  {
    name: "按月，￥329.00/月",
    value: 1,
    unit: "月(30天)",
    price: 329
  }, {
    name: "按年，￥2999.00/年",
    value: 2,
    unit: "年(356天)",
    price: 2999
  }
]

const colStyle = {
  minHeight: "40px",
  display: 'flex',
  justifyContent: "flex-start",
  alignItems: "center"
}

SettingPurchase.defaultProps = {
  type: "buy",

};

SettingPurchase.propTypes = {
  visible: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(["buy", "renew"]),
  item: PropTypes.object.isRequired,
  onOk: PropTypes.func,
  onCancel: PropTypes.func

};

export default SettingPurchase;
