export const routers = [

  {
    id: 1,
    key: "workbench",
    routeKey: "workbench",
    name: "工作台",
    iconClass: "icon-workbench",
    route: "/workbench"
  }, {
    id: 2,
    key: "structure",
    routeKey: "structure",
    route: '/structure',
    name: "组织架构",
    iconClass: "icon-structure",
    children: [
      {
        id: 21,
        name: "组织架构",
        key: "structure",
        routeKey: "structure",
        route: '/structure',
        icon: "https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/202005192011510001812699.svg",
        children: [
          {
            id: 211,
            name: "员工管理",
            key: "staff-manage",
            routeKey: "staff-manage",
            route: "/structure/structure/staff-manage",
          }, {
            id: 212,
            name: "角色权限",
            key: "role-permission",
            routeKey: "role-permission",
            route: "/structure/structure/role-permission",
          },
        ]
      }
    ]
  }, {
    id: 3,
    key: "setting",
    routeKey: "setting",
    route: "/setting",
    name: "设置",
    iconClass: "icon-setting",
    disabled: true,
    children: [
      {
        id: 31,
        name: "设置",
        key: "setting",
        routeKey: "setting",
        route: "/setting",
        icon: "https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/202005200944110001812880.svg",
        children: [
          {
            id: 311,
            name: "个人信息",
            key: "personal-info",
            routeKey: "personal-info",
            route: "/setting/setting/personal-info",
          }, {
            id: 312,
            name: "公司信息",
            key: "company-info",
            routeKey: "company-info",
            route: "/setting/setting/company-info",
          }, {
            id: 313,
            name: "产品购买",
            key: "product-buy",
            routeKey: "product-buy",
            route: "/setting/setting/product-buy",
          },
        ]
      }
    ]
  }, {
    id: 4,
    key: "resource",
    routeKey: "resource",
    route: '/resource',
    name: "资源库",
    icon: "home",
    children: [
      {
        id: 41,
        name: "商品库",
        key: "goods",
        routeKey: "goods",
        icon: "https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/202005200952500001812898.svg",
        children: [
          {
            id: 411,
            name: "商品管理",
            key: "manage",
            routeKey: "goodsManage",
            route: "/resource/goods/manage",
          }, {
            id: 412,
            name: "商品分类",
            key: "category",
            routeKey: "goodsCategory",
            route: "/resource/goods/category",
          }, {
            id: 413,
            name: "商品品牌",
            key: "brand",
            routeKey: "goodsBrand",
            route: "/resource/goods/brand",
          },
        ]
      }, {
        id: 42,
        name: "方案库",
        key: "scheme",
        routeKey: "scheme",
        icon: "https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/202005200954050001812903.svg",
        children: [
          {
            id: 421,
            name: "公共方案",
            key: "schemePublicManage",
            routeKey: "schemePublicManage",
            route: "/resource/scheme/schemePublicManage",
          }
        ]
      }, {
        id: 43,
        name: "素材库",
        key: "material",
        routeKey: "material",
        disabled:true,
        icon: "https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/202005200953360001812902.svg",
        children: [
          {
            id: 431,
            disabled:true,
            name: "素材库管理",
            key: "materialManage",
            routeKey: "materialManage",
            route: "/resource/material/materialManage",
          }
        ]
      }
    ]
  }, {
    id: 5,
    key: "app-workbench",
    routeKey: "app-workbench",
    route: '/app-workbench',
    name: "小程序工作台",
    icon: "home",
    children: [
      {
        id: 51,
        name: "小程序运营",
        key: "operate",
        routeKey: "app-operate",
        icon: "https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/202005201003570001812941.svg",
        children: [
          {
            id: 511,
            name: "商城运营",
            key: "market-operate",
            routeKey: "market-operate",
            route: "/app-workbench/operate/market-operate",
          }, {
            id: 512,
            name: "微页面",
            key: "micropage",
            routeKey: "micropage",
            route: "/app-workbench/operate/micropage",
          }, {
            id: 513,
            name: "爆品推荐",
            key: "recommend",
            routeKey: "recommend",
            route: "/app-workbench/operate/recommend",
          }, {
            id: 514,
            name: "营销活动",
            disabled: false,
            key: "marketing",
            routeKey: "marketing",
            route: "/app-workbench/operate/marketing",
          }, {
            id: 515,
            name: "文章/资讯",
            key: "article",
            routeKey: "article",
            route: "/app-workbench/operate/article",
          }, {
            id: 516,
            name: "消息推送",
            key: "message",
            routeKey: "message",
            route: "/app-workbench/operate/message",
          }
        ]
      }, {
        id: 52,
        name: "小程序配置",
        key: "setting",
        routeKey: 'app-authorize',
        icon: "https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/202005200944110001812880.svg",
        children: [
          {
            id: 521,
            name: "小程序授权",
            key: "app-authorize",
            routeKey: "app-authorize",
            route: "/app-workbench/setting/app-authorize",
          },
        ]
      }
    ]
  }, {
    id: 6,
    key: "customer",
    routeKey: "myCustomer",
    route: '/customer',
    name: "客户",
    icon: "home",
    children: [
      {
        id: 61,
        name: "客户",
        key: "customer",
        routeKey: "myCustomer",
        route: '/customer',
        icon: "https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/202005201003180001812940.svg",
        children: [
          {
            id: 611,
            name: "公司客户",
            key: "company",
            routeKey: "companyCustomer",
            route: "/customer/customer/company",
          }, {
            id: 612,
            name: "我的客户",
            key: "my",
            routeKey: "myCustomer",
            route: "/customer/customer/my",
          },
        ]
      }
    ]
  }
];
