import React, {useState, useEffect, useRef} from 'react';
import { Modal } from 'antd';
import {CloseCircleFilled} from '@ant-design/icons'
import PropTypes from 'prop-types';
import styles from './index.module.scss'

function GuideCover(props) {
  const {visible, onClose} = props
  const [step, setStep] = useState(0)

  const listRef = useRef("abc");
  const action = type => {


    console.log(listRef.current.style)
    let style = {
      width: basicWidth * contentList.length,
      left: `-${step * basicWidth}px`,
      transition: "all .5s ease"
    }
    switch (type) {
      case "prev":
        if (step > 0) {
          style.left = `-${(step - 1) * basicWidth}px`
          setStep(step - 1)
        }
        break;
      case "next":
        if (step < contentList.length - 1) {
          style.left = `-${(step + 1) * basicWidth}px`
          setStep(step + 1)
        }
        break;
      default:
        break;
    }
    console.log(style)
    listRef.current.style.left = style.left
  }

  const onShowCourse = () => {
    Modal.confirm({
      content: (
        <iframe src="https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/tmpdir/202006171338290001821719.pdf" frameborder="0" width="100%" height="600" />
      ),
      okButtonProps: {
        style: { display: 'none' }
      },
      cancelButtonProps: {
        style: { display: 'none' }
      },
      maskClosable: true,
      width: 1000,
    });
  }

  return (visible &&
    <div className={styles.cover}>
      <div className={styles.content}>
        <div className={styles.content_wrapper}>
          <div className={styles.img_container}
               style={{
                 width: basicWidth * contentList.length,
                 left: "0px",
                 transition: "all .5s ease"
               }} ref={listRef}>
            {contentList.map((item, index) => <img src={item} key={index} className={styles.content_img}/>)}
          </div>
          
          {step === 2 && <div className={styles.course_btn} onClick={onShowCourse} />}

          <div className={styles.action_container}>
            <img src={imgs.arrow} alt=""
                 className={`${styles.arrow_img_left} ${step === 0 ? styles.arrow_disabled : styles.arrow_active}`}
                 onClick={() => action("prev")}/>
            <img src={imgs.arrow} alt=""
                 className={`${styles.arrow_img_right} ${step === contentList.length - 1 ? styles.arrow_disabled : styles.arrow_active}`}
                 onClick={() => action("next")}/>
          </div>
        </div>
        <img className={styles.elephant} src={imgs.elephant}/>
        {step === contentList.length - 1 &&
        <div className={styles.close_btn} onClick={() => { setStep(0); onClose() }}>
          <img src={imgs.close} alt=""/>
        </div>}
      </div>
    </div>
  );
}

const contentList = [
  "https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/tmpdir/202006151452210001820845.png",
  "https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/tmpdir/202006151452560001820846.png",
  "https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/tmpdir/202006151453220001820847.png"
]

const basicWidth = 734

const imgs = {
  arrow: "https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/tmpdir/202006151447530001820843.png",
  close: "https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/tmpdir/202006151627060001820915.png",
  elephant: "https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/tmpdir/202006151448520001820844.png"
}

GuideCover.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default GuideCover;
