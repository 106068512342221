import {observable, action} from "mobx";
import httpClient from "utils/httpClient";
import api from "config/api";
import {addKey} from "utils/tools";

const {applet} = api;

class AppletStore {
  @observable appletInfo = {}; // 授权主体信息
  @observable homeDetail = null

  /**
   * 获取首页详情
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getHomeDetail = async params => {
    const data = await httpClient.get(applet.GET_HOME_DETAIL, {params});
    if (data.success) {
      this.homeDetail = data.data
    }
  }

  /**
   * 添加模块
   * @param params
   * @returns {Promise<*>}
   */
  @action
  postAddModule = async params => {
    const data = await httpClient.post(applet.POST_ADD_MODULE, {...params});
    return data;
  }
  /**
   * 删除模块
   * @param params
   * @returns {Promise<*>}
   */
  @action
  postDelModule = async params => {
    const data = await httpClient.post(applet.POST_DEL_MODULE, {...params});
    return data;
  }

  /**
   * 編輯模块
   * @param params
   * @returns {Promise<*>}
   */
  @action
  postEditModule = async params => {
    const data = await httpClient.post(applet.POST_EDIT_MODULE, {...params});
    return data;
  }

  /**
   * 商城首页添加、编辑
   * @param params
   * @returns {Promise<void>}
   */
  @action
  postHomeAddEdit = async params => {
    const data = await httpClient.post(applet.POST_HOME_ADD_EDIT, {...params});
    return data
  }

  /**
   * 微页面编辑
   * @param params
   * @returns {Promise<void>}
   */
  @action
  postMicroEdit = async params => {
    const data = await httpClient.post(applet.POST_MICRO_EDIT, {...params});
    return data
  }

  /**
   * 获取微页面列表
   * @param {*} params
   */
  @action
  async getMicropageList(params) {
    const res = await httpClient.post(applet.POST_GET_MICROPAGE_LIST, {...params});
    return res;
  }

  /**
   * 添加微页面
   * @param {*} params
   */
  @action
  async addMicropage(params) {
    const res = await httpClient.post(applet.POST_ADD_MICROPAGE, {...params});
    return res;
  }

  /**
   * 获取微页面详情
   * @param {*} params
   */
  @action
  async getMicropageDetail(params) {
    const res = await httpClient.get(applet.GET_MICROPAGE_DETAIL, {params});
    return res;
  }

  /**
   * 获取爆品推荐列表
   * @param {*} params
   */
  @action
  async getHotGoodsList(params) {
    const res = await httpClient.post(applet.POST_GET_HOT_GOODS_LIST, {...params});
    return res;
  }

  /**
   * 获取爆品推荐-添加商品列表
   * @param {*} params
   */
  @action
  async getAddGoodsList(params) {
    const res = await httpClient.post(applet.POST_GET_ADD_GOODS_LIST, {...params});
    return res;
  }

  /**
   * 添加爆品推荐
   * @param {*} params
   */
  @action
  async addHotGoods(params) {
    const res = await httpClient.post(applet.POST_ADD_HOT_GOODS, {...params});
    return res;
  }

  /**
   * 删除爆品推荐
   * @param {*} params
   */
  @action
  async delHotGoods(params) {
    const res = await httpClient.post(applet.POST_DELETE_HOT_GOODS, {...params});
    return res;
  }

  /**
   * 获取小程序授权主体信息
   * @param {*} params
   */
  @action
  async getAppletInfo(params) {
    const res = await httpClient.get(applet.GET_APPLET_INFO, {...params});
    const {success, data} = res;
    success && (this.appletInfo = data);
    return res;
  }

  /**
   * 获取小程序授权页面地址
   * @param {*} params
   */
  @action
  async getAppletAuthUrl(params) {
    const res = await httpClient.get(applet.GET_APPLET_AUTH_URL, {params});
    return res;
  }

  /**
   * 获取小程序体验版二维码
   * @param {*} params
   */
  @action
  async getExperienceUrl(params) {
    const res = await httpClient.get(applet.GET_APPLET_BETA_URL, {params, responseType: 'blob'});
    return res;
  }

  //营销活动
  /**
   * 查询营销活动列表
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getMarketingActivityList = async params => {
    const data = await httpClient.post(applet.POST_GET_MARKETING_ACTIVITY_LIST, {...params})
    if (data.success) {
      return addKey(data.data)
    }
  }

  /**
   * 创建营销活动
   * @param params
   * @returns {Promise<any | AxiosResponse<T>>}
   */
  @action
  createMarketingActivity = async params => {
    const data = await httpClient.post(applet.POST_CREATE_MARKETING_ACTIVITY, {...params})
    return data
  }

  /**
   * 编辑营销活动
   * @param params
   * @returns {Promise<any | AxiosResponse<T>>}
   */
  @action
  updateMarketingActivity = async params => {
    const data = await httpClient.post(applet.POST_UPDATE_MARKETING_ACTIVITY, {...params})
    return data
  }

  /**
   * 通过id查询单个营销活动
   * @param params
   * @returns {Promise<T>}
   */
  @action
  getMarketingActivityDetail = async params => {
    const data = await httpClient.get(applet.GET_MARKETING_ACTIVITY_BY_ID, {params})
    if (data.success) {
      return data.data
    }
  }

  /**
   * 营销活动报名数据
   * @param params
   * @returns {Promise<*>}
   */
  @action
  getMarketingActivityDataList=async params =>{
    const data=await httpClient.post(applet.POST_GET_MARKETING_ACTIVITY_DATA,{...params})
    if (data.success) {
      return addKey(data.data)
    }
  }

  /**
   * 导出活动报名数据
   * @param params
   * @returns {Promise<*>}
   */
  @action
  getDownloadActivityData=async params =>{
    return await httpClient.get(applet.GET_DOWNLOAD_ACTIVITY_DATA,{params})
  }

  /**
   * 下载导入结果模板
   * @param params
   * @returns {Promise<*>}
   */
  @action
  getDownloadResInputModel=async params =>{
    await httpClient.get(applet.GET_DOWNLOAD_RES_INPUT_MODEL,{params})
  }

  @action
  getResAwardList=async params=>{
    const data=await httpClient.get(applet.GET_RES_AWARD_LIST,{params})
    if(data.success){
      return data.data.map((item,index)=>Object.assign(item,{key:index}))
    }
  }


  //文章
  /**
   * 查询文章列表
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getArticleList = async params => {
    const data = await httpClient.post(applet.POST_GET_ARTICLE_LIST, {...params})
    if (data.success) {
      return addKey(data.data)
    }
  }

  /**
   * 新建文章
   * @param params
   * @returns {Promise<any | AxiosResponse<T>>}
   */
  @action
  createArticle = async params => {
    const data = await httpClient.post(applet.POST_CREATE_ARTICLE, {...params})
    return data
  }

  /**
   * 编辑文章
   * @param params
   * @returns {Promise<any | AxiosResponse<T>>}
   */
  @action
  updateArticle = async params => {
    const data = await httpClient.post(applet.POST_UPDATE_ARTICLE, {...params})
    return data
  }

  /**
   * 通过id查询单个文章
   * @param params
   * @returns {Promise<T>}
   */
  @action
  getArticleDetail = async params => {
    const data = await httpClient.get(applet.GET_ARTICLE_BY_ID, {params})
    if (data.success) {
      return data.data
    }
  }

  /**
   * 获取简单文章列表
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getSimpleArticleList = async params => {
    const data = await httpClient.post(applet.POST_GET_SIMPLE_ARTICLE_LIST, {...params})
    if (data.success) {
      return addKey(data.data)
    }
  }

  //消息

  /**
   * 查询消息列表
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getMessageList = async params => {
    const data = await httpClient.post(applet.POST_GET_MESSAGE_LIST, {...params})
    if (data.success) {
      return addKey(data.data)
    }
  }

  /**
   * 新建消息
   * @param params
   * @returns {Promise<any | AxiosResponse<T>>}
   */
  @action
  createMessage = async params => {
    const data = await httpClient.post(applet.POST_CREATE_MESSAGE, {...params})
    return data
  }

  /**
   * 更改消息状态
   * @param params
   * @returns {Promise<any | AxiosResponse<T>>}
   */
  @action
  messageAction = async params => {
    const data = await httpClient.post(applet.POST_MESSAGE_ACTION, {...params})
    return data
  }
}

export default new AppletStore();
