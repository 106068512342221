import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, Table, Button, Popover, message} from "antd";
import {inject, observer} from "mobx-react";

import styles from '../marketOperate.module.scss'
import {formatTime,addKey} from "utils/tools";

@inject("appletStore")
@observer
class AddMicro extends Component {
  state = {
    pageNo: 1,
    pageSize: 20,
    data:{list:[],total:0}
  }

  componentDidMount() {
    this._getData();
  }

  /**
   * 获取数据
   * @returns {Promise<void>}
   * @private
   */
  _getData =async () => {
    const {getMicropageList} = this.props.appletStore;
    const {pageNo, pageSize} = this.state
    let params = {
      pageNo,
      pageSize,
    }
    let res=await  getMicropageList(params)
    if(res.success){
      this.setState({
       data:addKey(res.data)
      })
    }
  }

  /**
   * 渲染表格
   * @returns {*}
   */
  renderTable = () => {
    const {item, onSelect} = this.props;
    const {data,pageNo}=this.state;
    const columns = [{
      title: '微页面名称',
      dataIndex:"pageName",
      render: (text) => <Popover content={text} title={false}>
        <span className={styles.table_name}>{text}</span>
      </Popover>
    }, {
      title: "创建时间",
      align: "center",
      dataIndex:"createTime",
      render: (text) => <span className={styles.table_time}>{formatTime(text)}</span>
    }, {
      title: "操作",
      align: "center",
      fixed: "right",
      render: (text, record) => record.id === item.id ?
        <Button disabled style={{width: 80}}>已选择</Button> :
        <Button style={{width: 80}} onClick={()=>onSelect(record)}>选择</Button>
    }]
    const pagination = {
      total: data.total,
      current: pageNo,
      showSizeChanger: true,
      showQuickJumper: true,
      pageSizeOptions: ["20", "50", "100"],
      defaultPageSize: 20,
      onChange: this.changePage,
      onShowSizeChange: this.changePageSize,
      showTotal: total => `共 ${total} 条数据`,
    }
    return <Table columns={columns}
                  dataSource={data.list}
                  pagination={pagination}
                  rowKey={record=>record.key}/>
  }
  /**
   * 页码改变
   * @param page
   * @param pageSize
   */
  changePage = (page, pageSize) => {
    this.setState({
      pageNo: page,
    }, this._getData)
  }

  /**
   * pageSize改变
   * @param current
   * @param size
   */
  changePageSize = (current, size) => {
    this.setState({
      pageNo: 1,
      pageSize: size,
    }, this._getData)
  }

  render() {
    const {visible, onCancel} = this.props;

    return (
      <Modal visible={visible}
             centered
             title="选择微页面"
             onCancel={onCancel}
             footer={false}
             width={700}
             zIndex={2000}
             bodyStyle={{
               maxHeight: 600,
               overflowY:"auto",
             }}
      >
        {this.renderTable()}
      </Modal>
    );
  }
}

AddMicro.propTypes = {
  visible: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSelect: PropTypes.func
};

export default AddMicro;
