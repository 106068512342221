import theme from "./theme"

const ENV = process.env.REACT_APP_API_ENV;
const apiHost = {
  dev: "http://dev-gateway.fxqifu.net/pangu",
  // test:"https://qa-gateway.fxqifu.net/pangu",
  test: "https://qa-gateway.peliten.net/pangu",
  prod: "https://gateway.fxqifu.net/pangu"
};

export const mainHost = apiHost[ENV];
export const DATA_CODE = {
  SUCCESS: 2000,
  QUERY_PARAMS_ERROR: 4000,
  LOGIN_ERROR: "100003",
  OTHER_ERROR: 5000
};

const prevImg = {
  rar: "https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/202005081708050000568070.png",
  ppt: "https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/202005081712020000603706.png",
}


const moduleImg = {
  module_banner: "https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/202005131106420003427001.png",
  module_goods: "https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/202005131107070003427002.png",
  module_swiper: "https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/202005131107430003427004.png",
  module_nav: "https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/202005131107240003427003.png",
  phone_head: "https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/202005131017140003426999.png",
  search_bar:"https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/202005141439070003427060.png",
  demo_img_180: "https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/202005211409550002614102.png",
  demo_img_230: "https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/202005211410120002614103.png",
  demo_img_350: "https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/202005211411280002614106.png",
  demo_img_345: "https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/202005211412080002614107.png",
}


export const config = {mainHost, theme, DATA_CODE, prevImg,moduleImg};
