import React, {useState, useEffect} from 'react';
import {Modal, Space, Button, Upload, Table, message} from "antd";
import styles from "./index.module.scss";
import PropTypes from "prop-types";
import {formatTime} from "utils/tools";
import stores from "stores"
import {mainHost} from "config";
import api from 'config/api'

const {applet} = api
const {appletStore} = stores

const modelUrl = "https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/202006111742230001819644.xlsx"

function MarketOperateActivityInput(props) {
  const {visible, item, onCancel, onOk} = props
  const [dataList, setDataList] = useState([]);
  const [canUpload, setCanUpload] = useState(true)
  /**
   * 请求列表调用时机
   */
  useEffect(() => {
    _getData();
  }, [])

  /**
   * 表格columns
   * @param type
   * @returns {*[]}
   */
  const columns = [
    {
      title: "获奖姓名",
      dataIndex: "name",
      align: "center",

    }, {
      title: "手机号",
      dataIndex: "phone",
      align: "center",
      render: (text) => text
    }, {
      title: "获奖等级",
      dataIndex: "awardLevel",
      align: "center",
    }, {
      title: "奖品",
      dataIndex: "awardItem",
      align: "center",
    }
  ]

  /**
   * 获取数据
   * @private
   */
  const _getData = async () => {
    const params = {
      activityId: item.id
    }

    let data = await appletStore.getResAwardList(params)
    setDataList(data)
  }

  const uploadChange = ({file, fileList}) => {
    console.log(file)
    if (file.status === 'done') {
      if (file.response.success) {
        message.success("上传成功！")
        setCanUpload(false);
        _getData();
      } else {
        message.error("上传失败，请重新上传！")
      }
    } else if (file.status === 'error') {
      message.error("上传失败，请重新上传！")
    }
  }

  const confirm = () => {
    onCancel();
    onOk();
  }
  return (
    <Modal title="结果录入" visible={visible} onCancel={onCancel} width={700}
           bodyStyle={{maxHeight: "600px", overflowY: "auto"}} onOk={confirm}>
      <div className={styles.action_line}>
        <Space>
          <span>添加获奖结果</span>
          <Upload action={mainHost + applet.POST_UPLOAD_RES_DATA}
                  data={{activityId: item.id}}
                  headers={{authority_token: localStorage.getItem("token")}}
                  showUploadList={false} onChange={uploadChange} disabled={+item.status === 3 || !canUpload}>
            <Button type="primary" disabled={+item.status === 3 || !canUpload}>表格导入</Button></Upload>
          {/*<Button type="link" onClick={() => appletStore.getDownloadResInputModel({})}>下载导入模板</Button>*/}
          <Button type="link" onClick={() => window.open(modelUrl, "_blank")}>下载导入模板</Button>
        </Space>
      </div>

      <Table dataSource={dataList}
             pagination={false}
             columns={columns}
             rowKey={record => record.key} style={{marginTop: 20}}/>

    </Modal>
  );

}

MarketOperateActivityInput.propTypes = {
  visible: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
}
export default MarketOperateActivityInput;
