import React, {useState, useEffect} from 'react';
import {Modal, Table, Statistic, message, Button, Space} from "antd";
import PropTypes from 'prop-types';
import styles from "./index.module.scss";
import stores from "stores"
import {formatTime} from "utils/tools";


const {appletStore} = stores


function MarketOperateMessageSelect(props) {
  const {visible, item, onCancel, onSelect} = props
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [dataList, setDataList] = useState({list: [], total: 0});
  /**
   * 请求列表调用时机
   */
  useEffect(() => {
    _getData();
  }, [pageNo, pageSize])
  /**
   * 页码改变
   * @param page
   * @param pageSize
   * @returns {Promise<void>}
   */
  const changePage = (page, pageSize) => {
    setPageNo(page);
  }

  /**
   * pageSize改变
   * @param current
   * @param size
   * @returns {Promise<void>}
   */
  const changePageSize = (current, size) => {
    setPageNo(1);
    setPageSize(size);
  }

  /**
   * 表格columns
   * @param type
   * @returns {*[]}
   */
  const columns = [
    {
      title: "标题",
      dataIndex: "title",
      align: "center",

    }, {
      title: "创建时间",
      dataIndex: "createTime",
      align: "center",
      render: (text) => formatTime(text)
    }, {
      title: "操作",
      dataIndex: "status",
      align: "center",
      render: (text, record) =>
        <Button disabled={item.id === record.id}
                onClick={() => selectItem(record)}>{item.id === record.id ? "已选择" : "选择"}</Button>
    }
  ]
  /**
   * 分页参数
   * @type {{total: number, current: number, pageSizeOptions: string[], defaultPageSize: number, onChange: changePage, showTotal: (function(*): string), onShowSizeChange: changePageSize, showQuickJumper: boolean, showSizeChanger: boolean}}
   */
  const pagination = {
    total: dataList.total,
    current: pageNo,
    showSizeChanger: true,
    showQuickJumper: true,
    pageSizeOptions: ["20", "50", "100"],
    defaultPageSize: 20,
    onChange: changePage,
    onShowSizeChange: changePageSize,
    showTotal: total => `共 ${total} 条数据`,
  }

  /**
   * 获取数据
   * @private
   */
  const _getData = async () => {
    const params = {
      pageNo, pageSize
    }

    let data = await appletStore.getSimpleArticleList(params)
    setDataList(data)
  }

  const selectItem = record => {
    onSelect(record);
    onCancel();
  }

  const refresh=()=>{
    if(pageNo===1){
      _getData()
    }else{
      setPageNo(1)
    }

  }

  return (
    <Modal title='选择文章' visible={visible} onCancel={onCancel} footer={false} width={800}
           bodyStyle={{maxHeight: "700px"}}>
      <div>
        <Space>
          <Button type="primary" onClick={()=>window.open("/app-workbench/operate/article/add","_self")}>新建文章</Button>
          <Button type="primary" onClick={refresh}>刷新列表</Button>
        </Space>

        <Table dataSource={dataList.list}
               pagination={pagination}
               columns={columns}
               rowKey={record => record.key} style={{marginTop: 20}}/>
      </div>

    </Modal>
  );
}

MarketOperateMessageSelect.propTypes = {
  visible: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  onCancel: PropTypes.func
};

export default MarketOperateMessageSelect;
