import localStore from "./local"
import commonStore from "./common";
import roleStore from './role';
import employeeStore from './employee';
import goodsStore from './goods';
import customerStore from './customer';
import appletStore from './applet';
import homeStore from './home';
import settingStore from './setting'
import schemeStore from './scheme'


export default {
  localStore,
  homeStore,
  commonStore,
  roleStore,
  employeeStore,
  goodsStore,
  customerStore,
  appletStore,
  settingStore,
  schemeStore
}
